<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">科室绩效数据填报</h2>
                <div class="hosrow">
                    <div>
                        <el-date-picker v-model="searDate" type="month" format="yyyy-MM" value-format="yyyy-MM" placeholder="请选择数据月份" @change="searcDate(searDate)">
                        </el-date-picker>
                    </div>
                    <div>
                        <el-select v-model="searDepVal" placeholder="请选择科室" clearable @change="depSearch(searDepVal)">
                            <el-option v-for="item in departoptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-button type="primary" @click="addmonfil">新建月度填报</el-button>
                    </div>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="departmentName" label="科室名称" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="绩效数据填报名称" align="center">
                    </el-table-column>
                    <el-table-column prop="allocationSchemeName" label="方案名称" align="center">
                    </el-table-column>
                    <el-table-column prop="month" label="填报月份" align="center" width="100">
                        <template slot-scope="scope">
                            <div>
                                {{scope.row.year}}年{{scope.row.month}}月
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="填报状态" align="center" width="80">
                        <template slot-scope="scope">
                            <!-- 填报状态(0未填报完   1已填报完 ) -->
                            <div class="cheActi" v-if="scope.row.status=='1'">
                                √
                            </div>
                            <div class="chegre" v-else>
                                √
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="填报时间" align="center" width="160">
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align="center" width="200px">
                        <template slot-scope="scope">
                            <div class="remakSty">{{scope.row.remark}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="数据锁定" align="center" width="80">
                        <template slot-scope="scope">
                            <!--数据锁定状态（0未锁定  1已锁定） -->
                            <el-switch v-model="scope.row.status" active-color="#409EFF" inactive-color="#929292" :active-value="1" :inactive-value="0">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="190">
                        <template style="display: flex" slot-scope="scope">
                            <!-- <el-button v-if="(scope.row.year==currYea) && (scope.row.month==currMon)" size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button v-else size="mini" type="info"><i class="el-icon-edit"></i></el-button> -->

                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>

                            <el-button class="iconBtn" size="mini" type="primary" @click="fillVieDat(scope.row)"><i class="iconfont icon-shujuchakan-05"></i></el-button>
                            <el-button class="iconBtn" size="mini" type="primary" @click="viwWorkIndq(scope.row)"><i class="iconfont icon-tianbao"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 新建月度填报 -->
        <el-dialog :title="monthtit" :visible.sync="AdddialogVisible" :before-close="AdddialogVisibleClose" width="410px">
            <el-form :model="formModel" ref="formRef" :rules="formRules" label-width="150px" class="forClas">

                <el-form-item label="填报数据类型：" prop="position">
                    <el-radio-group v-model="formModel.position" @change="posChange(formModel.position)">
                        <el-radio label="0">医生</el-radio>
                        <el-radio label="1">护士</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="绩效数据填报名称：" prop="name">
                    <el-input type="text" v-model="formModel.name" placeholder="请填写绩效数据填报名称"></el-input>
                </el-form-item>

                <el-form-item label="科室：" prop="departmentId">
                    <el-select v-model="formModel.departmentId" placeholder="请选择科室" clearable @change="depChange(formModel.departmentId)">
                        <el-option v-for="item in departoptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="分配方案：" prop="allplan">
                    <el-select v-model="formModel.allplan" placeholder="请选择分配方案" clearable @change="planChange(formModel.allplan)">
                        <el-option v-for="item in planoptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="数据月份：" prop="date">
                    <el-date-picker v-model="formModel.date" type="month" format="yyyy-MM" value-format="yyyy-MM" :picker-options="pickerOptions" placeholder="请选择数据月份" @change="dateVal(formModel.date)">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="备注：">
                    <el-input type="textarea" maxlength="250" show-word-limit v-model="formModel.remark" placeholder="请填写备注"></el-input>
                </el-form-item>

                <div class="btnCen">
                    <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="addKeshi">{{diaBtn}}</el-button>
                     <el-button v-else type="info" :loading="addDepClickKing"></el-button>
                </div>

            </el-form>
        </el-dialog>

        <el-dialog title="科室填报数据" :visible.sync="depfilldialogVisible" :before-close="depfilldialogVisibleClose" top="1vh" width="90%">
            <div class="filDepClas">
                <div style="margin-right:10px;">
                    <div style="font-weight: bold;font-size: 16px;">填报月份:{{fillMonth}}月</div>
                </div>
                <div style="margin-right:10px;">
                    <el-upload ref="uploadone" :headers="token" :action="downurl" :file-list="fileList" :limit="limitUpload" style="display: inline-block" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" :on-success="successFile" :on-progress="uploading">
                        <el-button type="primary" v-if="importExpClick" :loading="importExpClickTwo">导入填报数据</el-button>
                        <el-button type="info" v-else :loading="importExpClickTwo"></el-button>
                    </el-upload>
                </div>

                <div>
                    <el-button type="primary" @click="assindito">科室绩效考核指标</el-button>
                </div>
                <div class="filDiv">
                    <el-cascader v-model="poValue" expand-trigger="hover" :options="postOptions" placeholder="请选择岗位" popper-class="disableFirstLevel" :props="props" ref='postSelectRef' clearable @change='postValueChange'></el-cascader>
                </div>
                <div class="filDiv">
                    <el-select v-model="filStatus" placeholder="请选择填报状态">
                        <el-option v-for="item in statusoptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="filDiv">
                    <el-input v-model="filName" placeholder="请填写科室人员姓名" clearable @keyup.enter.native="searchfilName" @blur="searchfilName" @clear="searchfilName"></el-input>
                </div>
                <div class="filDiv">
                    <el-button type="primary" @click="addextper">添加外部人员</el-button>
                </div>
                <div class="filDiv">
                    <el-button type="primary" @click="perexportExcel" v-if="cesuanExpClick" :loading="cesuanExpClickTwo">导出数据</el-button>
                    <el-button type="info" v-else :loading="cesuanExpClickTwo"></el-button>
                </div>
            </div>
            <el-table :data="deptabData" border center>
                <el-table-column type="index" label="序号" width="55" align="center">
                </el-table-column>
                <el-table-column prop="workId" label="工号" align="center">
                </el-table-column>
                <el-table-column prop="departmentUserName" label="姓名" align="center">
                </el-table-column>
                <el-table-column prop="departmentName" label="科室名称" align="center">
                </el-table-column>
                <el-table-column prop="basicLibraryStatus" label="基本指标" align="center" width="100">
                    <!-- 基本指标填报状态（0未填报完  1已填报完毕） -->
                    <template slot-scope="scope">
                        <div class="cheActi" v-if="scope.row.basicLibraryStatus=='1'">
                            √
                        </div>
                        <div class="chegre" v-else>
                            √
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="workloadLibraryStatus" label="工作量指标" align="center" width="100">
                    <template slot-scope="scope">
                        <div class="cheActi" v-if="scope.row.workloadLibraryStatus=='1'">
                            √
                        </div>
                        <div class="chegre" v-else>
                            √
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="otherLibraryStatus" label="其他个人指标" align="center" width="110">
                    <!-- 其他个人指标填报状态（0未填报完  1已填报完毕） -->
                    <template slot-scope="scope">
                        <div class="cheActi" v-if="scope.row.otherLibraryStatus=='1'">
                            √
                        </div>
                        <div class="chegre" v-else>
                            √
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="auditStatus" label="审核状态" align="center" width="100">
                    <template slot-scope="scope">
                        <!-- 审核状态（0正常  1审核未通过） -->
                        <el-switch v-model="scope.row.auditStatus" @change="editSta(scope.row)" active-color="#409EFF" inactive-color="#929292" :active-value="0" :inactive-value="1">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="190">
                    <template style="display: flex" slot-scope="scope">
                        <el-tooltip content="个人信息查看" placement="top">
                            <el-button size="mini" type="primary" @click="perInfo(scope.row)"><i class="iconfont icon-gerenxinxi"></i></el-button>
                        </el-tooltip>
                        <el-tooltip content="个人信息查看" placement="top">
                            <el-button size="mini" type="primary" @click="viwWorkInd(scope.row)"><i class="iconfont icon-zhibiaomulu"></i></el-button>
                        </el-tooltip>
                        <el-tooltip content="个人信息查看" placement="top">
                            <el-button size="mini" type="primary" @click="perfill(scope.row)"><i class="iconfont icon-gerenzhongxin-gerenxinxi"></i></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background @current-change="handledepCurrentChange" :current-page="deppagNum" :page-size="deppagSize" layout="prev, pager, next" :total="deptotal">
            </el-pagination>
            <div class="btnCen">
                <el-button type="primary" @click="depfilldialogVisibleClose">关闭</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="departmentUserName+'个人指标填报'" :visible.sync="perfilldialogVisible" top="1vh" :before-close="perfilldialogVisibleClose" width="70%" :close-on-click-modal='false'>
            <el-steps :active="active" finish-status="success" align-center>
                <el-step v-for="(item,index) of stepParams" :key="index" :title="item.title" @click.native="tabSwitchBtn(item)" />
            </el-steps>
            <el-form ref="perform" :model="perforModel" :rules="perRules" class="perForClas" label-width="auto">
                <div v-for="(item,index) of perforModel.basData" :key="index">

                    <div class="smatit">{{item.name}}</div>

                    <!-- 护士附加职责 -->
                    <div v-if="item.name=='护士附加职责'">
                        <div class="addClas">
                            <el-form-item prop="addValue">
                                <el-tree :check-on-click-node="true" :default-checked-keys='checkKeys' :data="item.performanceAllocationSchemeDetailList" show-checkbox node-key="id" ref="nurtree" :props="defaultProps" @check="nurindiChange">
                                    <span class="custom-tree-node" slot-scope="{ node, data }">
                                        <el-popover placement="bottom" width="400" trigger="click">
                                            <div>指标内涵：{{data.intension}}</div>
                                            <div>指标说明：{{data.explain}}</div>
                                            <div v-if="data.characteristic=='0'">特性：系数加分</div>
                                            <div v-else-if="data.characteristic=='1'">特性：系数减分</div>
                                            <div v-else-if="data.characteristic=='2'">特性：固定金额</div>
                                            <div v-else-if="data.characteristic=='3'">特性：评分</div>
                                            <div v-else-if="data.characteristic=='4'">特性：百分比减分</div>
                                            <div v-if="data.unit=='0'">单位：次</div>
                                            <div v-else-if="data.unit=='1'">单位：人/次</div>
                                            <div v-else-if="data.unit=='2'">单位：半天/次</div>
                                            <div v-else-if="data.unit=='3'">单位：天</div>
                                            <div>建议分值：{{data.suggestedScore}}</div>
                                            <i class="el-icon-question" slot="reference"></i>
                                        </el-popover>
                                        <span> {{ node.label }}</span>
                                    </span>
                                </el-tree>
                            </el-form-item>
                        </div>

                    </div>
                    <!-- 医生附加职责 -->
                    <div v-else-if="item.name=='医生附加职责'">
                        <div class="addClas">
                            <el-form-item prop="addValue">
                                <el-tree :check-on-click-node="true" :default-checked-keys='checkKeys' :data="item.performanceAllocationSchemeDetailList" show-checkbox node-key="id" ref="doctree" :props="defaultProps" @check="docindiChange">
                                    <span class="custom-tree-node" slot-scope="{ node, data }">
                                        <el-popover placement="bottom" width="400" trigger="click">
                                            <div>指标内涵：{{data.intension}}</div>
                                            <div>指标说明：{{data.explain}}</div>
                                            <div v-if="data.characteristic=='0'">特性：系数加分</div>
                                            <div v-else-if="data.characteristic=='1'">特性：系数减分</div>
                                            <div v-else-if="data.characteristic=='2'">特性：固定金额</div>
                                            <div v-else-if="data.characteristic=='3'">特性：评分</div>
                                            <div v-else-if="data.characteristic=='4'">特性：百分比减分</div>
                                            <div v-if="data.unit=='0'">单位：次</div>
                                            <div v-else-if="data.unit=='1'">单位：人/次</div>
                                            <div v-else-if="data.unit=='2'">单位：半天/次</div>
                                            <div v-else-if="data.unit=='3'">单位：天</div>
                                            <div>建议分值：{{data.suggestedScore}}</div>
                                            <i class="el-icon-question" slot="reference"></i>
                                        </el-popover>
                                        <span> {{ node.label }}</span>
                                    </span>
                                </el-tree>
                            </el-form-item>

                        </div>
                    </div>
                    <!-- 其他 -->
                    <div v-else>
                        <div class="popClass">
                            <div v-for="(it,ind) of item.performanceAllocationSchemeDetailList" :key="ind">
                                <!-- 评分的 -->
                                <el-form-item v-if="it.characteristic=='3'" class="worClaSc" :label="it.name+'：'" :prop="'basData.'+index+'.performanceAllocationSchemeDetailList.' + ind + '.ratingDimension'" :rules="{
            required: true,
            message: '请选择'+it.name,
            trigger: 'blur',
          }">
                                    <el-popover placement="bottom" width="400" trigger="click">
                                        <div>指标内涵：{{it.intension}}</div>
                                        <div>指标说明：{{it.explain}}</div>
                                        <div v-if="it.characteristic=='0'">特性：系数加分</div>
                                        <div v-else-if="it.characteristic=='1'">特性：系数减分</div>
                                        <div v-else-if="it.characteristic=='2'">特性：固定金额</div>
                                        <div v-else-if="it.characteristic=='3'">特性：评分</div>
                                        <div v-else-if="it.characteristic=='4'">特性：百分比减分</div>
                                        <div v-if="it.unit=='0'">单位：次</div>
                                        <div v-else-if="it.unit=='1'">单位：人/次</div>
                                        <div v-else-if="it.unit=='2'">单位：半天/次</div>
                                        <div v-else-if="it.unit=='3'">单位：天</div>
                                        <div>建议分值：{{it.suggestedScore}}</div>
                                        <div slot="reference"><i class="el-icon-question"></i></div>
                                    </el-popover>
                                    <el-radio-group v-model="it.ratingDimension" class="raioClass">
                                        <el-radio label="2">
                                            <span class="radTex">优</span>
                                            <el-form-item :prop="'basData.'+index+'.performanceAllocationSchemeDetailList.' + ind + '.goodScore'" :rules="{
            required: true,
            message: '请输入配置分值',
            trigger: 'blur',
          }">
                                                <el-input disabled v-model="it.goodScore" type="number" @input="change($event)" :placeholder="'请输入配置分值'"></el-input>
                                            </el-form-item>
                                        </el-radio>
                                        <el-radio label="1">
                                            <span class="radTex">一般</span>
                                            <el-form-item :prop="'basData.'+index+'.performanceAllocationSchemeDetailList.' + ind + '.generalScore'" :rules="{
            required: true,
            message: '请输入配置分值',
            trigger: 'blur',
          }">
                                                <el-input disabled v-model="it.generalScore" type="number" @input="change($event)" :placeholder="'请输入配置分值'"></el-input>
                                            </el-form-item>
                                        </el-radio>
                                        <el-radio label="0">
                                            <span class="radTex">差</span>
                                            <el-form-item :prop="'basData.'+index+'.performanceAllocationSchemeDetailList.' + ind + '.poorScore'" :rules="{
            required: true,
            message: '请输入配置分值',
            trigger: 'blur',
          }">
                                                <el-input disabled v-model="it.poorScore" type="number" @input="change($event)" :placeholder="'请输入配置分值'"></el-input>
                                            </el-form-item>
                                        </el-radio>
                                    </el-radio-group>

                                </el-form-item>

                                <el-form-item v-else :label="it.name+'：'" :prop="'basData.'+index+'.performanceAllocationSchemeDetailList.' + ind + '.score'" :rules="{
            required: true,
            message: '请输入配置分值',
            trigger: 'blur',
          }">
                                    <el-popover placement="bottom" width="400" trigger="click">
                                        <div>指标内涵：{{it.intension}}</div>
                                        <div>指标说明：{{it.explain}}</div>
                                        <div v-if="it.characteristic=='0'">特性：系数加分</div>
                                        <div v-else-if="it.characteristic=='1'">特性：系数减分</div>
                                        <div v-else-if="it.characteristic=='2'">特性：固定金额</div>
                                        <div v-else-if="it.characteristic=='3'">特性：评分</div>
                                        <div v-else-if="it.characteristic=='4'">特性：百分比减分</div>
                                        <div v-if="it.unit=='0'">单位：次</div>
                                        <div v-else-if="it.unit=='1'">单位：人/次</div>
                                        <div v-else-if="it.unit=='2'">单位：半天/次</div>
                                        <div v-else-if="it.unit=='3'">单位：天</div>
                                        <div>建议分值：{{it.suggestedScore}}</div>
                                        <div slot="reference"><i class="el-icon-question"></i></div>
                                    </el-popover>
                                    <el-input type="number" @input="change($event)" v-model="it.score" :placeholder="'请填写'+it.name"></el-input>
                                </el-form-item>
                            </div>
                        </div>

                    </div>

                </div>
            </el-form>

            <div class="btnDiv">
                <div class="btnCen" v-if="perShow">
                    <el-button style="margin-top: 12px;" type="primary" @click="preste">上一步</el-button>
                </div>

                <div class="btnCen" v-if="nexShow">
                    <!-- <el-button style="margin-top: 12px;" type="primary" @click="next">下一步</el-button> -->
                    <el-button style="margin-top: 12px;" v-if="nextClick" type="primary" :loading="nextClickKing" @click="editsubmit('下一步')">下一步</el-button>
                    <el-button style="margin-top: 12px;" v-else type="info" :loading="nextClickKing"></el-button>
                </div>

                <!-- 下一步：调取接口的按钮（回显时候） -->
                <div class="btnCen" v-if="nexTwoShow">
                    <el-button style="margin-top: 12px;" v-if="nextClick" type="primary" :loading="nextClickKing" @click="editsubmit('下一步')">下一步</el-button>
                     <el-button style="margin-top: 12px;" v-else type="info" :loading="nextClickKing"></el-button>
                </div>

                <div class="btnCen" v-if="echoShow">
                    <el-button style="margin-top: 12px;" v-if="saveextClick" type="primary" :loading="saveextClickKing" @click="editsubmit('修改')">修改</el-button>
                     <el-button style="margin-top: 12px;" v-else type="info" :loading="saveextClickKing"></el-button>
                </div>

                <div class="btnCen" v-if='saveShow'>
                    <el-button style="margin-top: 12px;" v-if="saveextClick" type="primary" :loading="saveextClickKing" @click="submit">保存</el-button>
                     <el-button style="margin-top: 12px;" v-else type="info" :loading="saveextClickKing"></el-button>
                </div>

                <div class="btnCen">
                    <el-button style="margin-top: 12px;" type="primary" @click="perfilldialogVisibleClose">关闭</el-button>
                </div>
            </div>

        </el-dialog>

        <el-dialog :title="departmentUserName+'工作量指标查看'" :visible.sync="workloaddialogVisible" top="1vh" width="70%" :before-close="workloaddialogVisibleClose" :close-on-click-modal='false'>
            <el-form ref="performRef" :model="ourforModel" :rules="perRules" label-width="auto">

                <div style="width:95%;margin:0 auto;">

                    <!-- <div class="workSear">
                        <span>指标类型：</span>
                        <el-select class="searTyCla" v-model="seartype" clearable placeholder="请选择指标类型" @change="typeChange(seartype)">
                            <el-option v-for="item in taroptions" :key="item.id" :label="item.name" :value="item.name">
                            </el-option>
                        </el-select>
                    </div> -->

                    <div>
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="医疗服务项目" name="first">
                                <div v-if="JSON.stringify(ourforModel.workProList) !== '[]'">
                                    <div  v-for="(item,index) of ourforModel.workProList"  :key="index">
                                        <el-table v-if="item.name=='医疗服务项目'" ref=" multipleTable" max-height="500" center border :data="item.performanceAllocationSchemeDetailList.slice((onest-1)*onelimit,onest*onelimit)" tooltip-effect="dark" style="width: 100%">
                                            <el-table-column type="index" label="序号" width="55" align="center" :index='indexOneMethods'>
                                            </el-table-column>
                                            <el-table-column prop="performanceIndicatorThirdLibrary.name" label="指标名称" align="center">
                                            </el-table-column>
                                            <el-table-column prop="performanceIndicatorThirdLibrary.intension" label="指标内涵" align="center">
                                                <template slot-scope="scope">
                                                    <el-tooltip class="item" effect="dark" placement="top">
                                                        <div class="heiClas">{{scope.row.performanceIndicatorThirdLibrary.intension}}</div>
                                                        <div style="width: 500px" slot="content">
                                                            <div>{{scope.row.performanceIndicatorThirdLibrary.intension}}</div>
                                                        </div>
                                                    </el-tooltip>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="performanceIndicatorThirdLibrary.explain" label="指标说明" align="center">
                                                <template slot-scope="scope">
                                                    <el-tooltip class="item" effect="dark" placement="top">
                                                        <div class="heiClas">{{scope.row.performanceIndicatorThirdLibrary.explain}}</div>
                                                        <div style="width: 500px" slot="content">
                                                            <div>{{scope.row.performanceIndicatorThirdLibrary.explain}}</div>
                                                        </div>
                                                    </el-tooltip>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="performanceIndicatorThirdLibrary.characteristic" label="特性" align="center">
                                                <template slot-scope="scope">
                                                    <div v-if="scope.row.performanceIndicatorThirdLibrary.characteristic=='0'">加分</div>
                                                    <div v-else-if="scope.row.performanceIndicatorThirdLibrary.characteristic=='1'">减分</div>
                                                    <div v-else-if="scope.row.performanceIndicatorThirdLibrary.characteristic=='2'">固定金额</div>
                                                    <div v-else-if="scope.row.performanceIndicatorThirdLibrary.characteristic=='3'">评分</div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="score" label="值" align="center" width="220px">
                                                <template slot-scope="scope">
                                                    <el-form-item class="diffInp" :prop="'workProList.'+index+'.performanceAllocationSchemeDetailList.'+scope.$index+'.score'" :rules="{required:true, message:'请输入值', trigger:'blur'}">
                                                        <el-input v-model="scope.row.score" type="number" @input="change($event)" :placeholder="'请输入值'"></el-input>
                                                    </el-form-item>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                        <el-pagination v-if="item.name=='医疗服务项目'" layout="prev, pager, next" :current-page.sync="onest" :page-size="onelimit" :total="onecount">
                                        </el-pagination>

                                    </div>
                                </div>
                                <div v-else>
                                    暂无数据
                                </div>

                            </el-tab-pane>
                            <el-tab-pane label="病历病种" name="second">
                                <div v-if="JSON.stringify(ourforModel.workMedList) !== '[]'">
                                    <div  v-for="(item,index) of ourforModel.workMedList"  :key="index">
                                        <el-table v-if="item.name=='病历病种'" ref=" multipleTable" max-height="500" center border :data="item.performanceAllocationSchemeDetailList.slice((st-1)*limit,st*limit)" tooltip-effect="dark" style="width: 100%">
                                            <el-table-column type="index" label="序号" width="55" align="center" :index='indexMethods'>
                                            </el-table-column>
                                            <el-table-column prop="performanceIndicatorThirdLibrary.name" label="指标名称" align="center">
                                            </el-table-column>
                                            <el-table-column prop="performanceIndicatorThirdLibrary.intension" label="指标内涵" align="center">
                                                <template slot-scope="scope">
                                                    <el-tooltip class="item" effect="dark" placement="top">
                                                        <div class="heiClas">{{scope.row.performanceIndicatorThirdLibrary.intension}}</div>
                                                        <div style="width: 500px" slot="content">
                                                            <div>{{scope.row.performanceIndicatorThirdLibrary.intension}}</div>
                                                        </div>
                                                    </el-tooltip>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="performanceIndicatorThirdLibrary.explain" label="指标说明" align="center">
                                                <template slot-scope="scope">
                                                    <el-tooltip class="item" effect="dark" placement="top">
                                                        <div class="heiClas">{{scope.row.performanceIndicatorThirdLibrary.explain}}</div>
                                                        <div style="width: 500px" slot="content">
                                                            <div>{{scope.row.performanceIndicatorThirdLibrary.explain}}</div>
                                                        </div>
                                                    </el-tooltip>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="performanceIndicatorThirdLibrary.characteristic" label="特性" align="center">
                                                <template slot-scope="scope">
                                                    <div v-if="scope.row.performanceIndicatorThirdLibrary.characteristic=='0'">加分</div>
                                                    <div v-else-if="scope.row.performanceIndicatorThirdLibrary.characteristic=='1'">减分</div>
                                                    <div v-else-if="scope.row.performanceIndicatorThirdLibrary.characteristic=='2'">固定金额</div>
                                                    <div v-else-if="scope.row.performanceIndicatorThirdLibrary.characteristic=='3'">评分</div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="score" label="值" align="center" width="220px">
                                                <template slot-scope="scope">
                                                    <el-form-item class="diffInp" :prop="'workMedList.'+index+'.performanceAllocationSchemeDetailList.'+scope.$index+'.score'" :rules="{required:true, message:'请输入值', trigger:'blur'}">
                                                        <el-input v-model="scope.row.score" type="number" @input="change($event)" :placeholder="'请输入值'"></el-input>
                                                    </el-form-item>
                                                </template>
                                            </el-table-column>
                                        </el-table>

                                        <el-pagination v-if="item.name=='病历病种'" layout="prev, pager, next" :current-page.sync="st" :page-size="limit" :total="count">
                                        </el-pagination>

                                    </div>
                                </div>
                                <div v-else>
                                    暂无数据
                                </div>

                            </el-tab-pane>
                            <el-tab-pane label="业务量" name="third">
                                <div class="workInp">
                                    <div v-if="JSON.stringify(ourforModel.trafficVal) !== '[]'">
                                        <div class="workInpu" v-for="(item,index) of ourforModel.trafficVal" :key='index'>
                                            <div v-for="(it,id) of item.performanceAllocationSchemeDetailList" :key='id' class="firsDIv">
                                                <span class="worTit">{{it.performanceIndicatorThirdLibrary.name}}：</span>
                                                <el-form-item class="bigClass" :prop="'trafficVal.'+index+'.performanceAllocationSchemeDetailList.' + id + '.performanceIndicatorThirdLibrary.score'" :rules="{required:true, message:'请输入值', trigger:'blur'}">
                                                    <el-input class="sorClas" v-model="it.performanceIndicatorThirdLibrary.score" type="number" @input="change($event)" :placeholder="'请输入值'"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        暂无数据
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
                <div style="display:flex;justify-content:center;">
                    <div class="btnCen" style="margin-right:10px;">
                        <el-button style=" margin-top: 12px;" v-if="subextClick" type="primary" :loading="subextClickKing" @click="nextSub">提交</el-button>
                         <el-button style="margin-top: 12px;" v-else type="info" :loading="subextClickKing"></el-button>
                    </div>

                    <div class="btnCen">
                        <el-button style=" margin-top: 12px;" type="primary" @click="workloaddialogVisibleClose">关闭</el-button>
                    </div>
                </div>

            </el-form>
        </el-dialog>

        <el-dialog :title="departmentUserName+'信息查看'" top="1vh" :visible.sync="perinfodialogVisible" width="700px" :before-close="perinfodialogVisibleClose">
            <el-form class="perClas" :model="AddDateModel" ref="AddDateRef" :inline="true" label-width="100px">
                <el-form-item label="工号：" prop="jobnum">
                    <el-input disabled v-model="AddDateModel.jobnum" placeholder="请填写工号"></el-input>
                </el-form-item>
                <el-form-item label="姓名：" prop="name">
                    <el-input disabled v-model="AddDateModel.name" placeholder="请填写姓名"></el-input>
                </el-form-item>
                <el-form-item label="身份证：" prop="idcard">
                    <el-input disabled v-model="AddDateModel.idcard" placeholder="请填写身份证"></el-input>
                </el-form-item>
                <el-form-item label="性别：" prop="sex">
                    <el-select disabled v-model="AddDateModel.sex" placeholder="请选择性别">
                        <el-option v-for="item in sexoptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="出生日期：" prop="age" class="ageClas">
                    <el-date-picker disabled value-format="yyyy-MM-dd" v-model="AddDateModel.age" type="date" placeholder="请选择出生日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item prop="lengthOfService" class="scoClas">
                    <el-popover placement="bottom" width="200" trigger="click">
                        <span>指标分值：{{basscoList.seniority?basscoList.seniority:'暂无'}}</span>
                        <div slot="reference">工龄：<i class="el-icon-question"></i></div>
                    </el-popover>

                    <el-select disabled v-model="AddDateModel.lengthOfService" placeholder="请选择工龄" clearable>
                        <el-option v-for="item in lengthOfServiceoptions" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="education" class="scoClas">

                    <el-popover placement="bottom" width="200" trigger="click">
                        <span>指标分值：{{basscoList.education?basscoList.education:'暂无'}}</span>
                        <label slot="reference">学历：<i class="el-icon-question"></i></label>
                    </el-popover>

                    <el-select disabled v-model="AddDateModel.education" placeholder="请选择学历" clearable>
                        <el-option v-for="item in educationRange" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="title" class="scoClas">
                    <el-popover placement="bottom" width="200" trigger="click">
                        <span>指标分值：{{basscoList.title?basscoList.title:'暂无'}}</span>
                        <label slot="reference">职称：<i class="el-icon-question"></i></label>
                    </el-popover>
                    <el-select disabled v-model="AddDateModel.title" placeholder="请选择职称" clearable>
                        <el-option v-for="item in titleRange" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="post" class="scoClas">

                    <el-popover placement="bottom" width="200" trigger="click">
                        <span>指标分值：{{basscoList.duties?basscoList.duties:'暂无'}}</span>
                        <label slot="reference">职务：<i class="el-icon-question"></i></label>
                    </el-popover>

                    <el-select disabled v-model="AddDateModel.post" placeholder="请选择职务">
                        <el-option v-for="item in postoptions" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="polout" class="scoClas">

                    <el-popover placement="bottom" width="200" trigger="click">
                        <span>指标分值：{{basscoList.poloutlook?basscoList.poloutlook:'暂无'}}</span>
                        <label slot="reference">政治面貌：<i class="el-icon-question"></i></label>
                    </el-popover>

                    <el-select disabled v-model="AddDateModel.polout" placeholder="请选择政治面貌">
                        <el-option v-for="item in poloptions" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item prop="autstr" class="scoClas">

                    <el-popover placement="bottom" width="200" trigger="click">
                        <span>指标分值：{{basscoList.organization?basscoList.organization:'暂无'}}</span>
                        <label slot="reference">编制：<i class="el-icon-question"></i></label>
                    </el-popover>

                    <el-select disabled v-model="AddDateModel.autstr" placeholder="请选择编制">
                        <el-option v-for="item in autoptions" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="emptype" class="scoClas">

                    <el-popover placement="bottom" width="200" trigger="click">
                        <span>指标分值：{{basscoList.ployetype?basscoList.ployetype:'暂无'}}</span>
                        <label slot="reference">职工类型：<i class="el-icon-question"></i></label>
                    </el-popover>

                    <el-select disabled v-model="AddDateModel.emptype" placeholder="请选择职工类型">
                        <el-option v-for="item in empoptions" :key="item.dictCode" :label="item.dictLabel" :value="item.dictLabel">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属部门：" prop="departme">
                    <el-cascader disabled :value="depValue" expand-trigger="hover" :options="depOptions" placeholder="请选择所属部门" ref='countrySelectRef' clearable></el-cascader>
                </el-form-item>

                <el-form-item prop="ganpost" class="scoClas">
                    <el-popover placement="bottom" width="200" trigger="click">
                        <span>指标分值：{{basscoList.post?basscoList.post:'暂无'}}</span>
                        <label slot="reference">岗位：<i class="el-icon-question"></i></label>
                    </el-popover>
                    <el-cascader disabled v-model="postValue" expand-trigger="hover" :options="postOptions" placeholder="请选择岗位" popper-class="disableFirstLevel" :props="props" ref='postSelectRef' clearable></el-cascader>
                </el-form-item>
                <el-form-item label="学科：" prop="bralear">
                    <el-select disabled v-model="AddDateModel.bralear" multiple placeholder="请选择学科" clearable>
                        <el-option v-for="item in baroptions" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注：" prop="text">
                    <el-input disabled type="textarea" v-model="AddDateModel.text"></el-input>
                </el-form-item>

                <el-form-item style="width:100%;text-align:center;">
                    <div class="btnCen">
                        <el-button type="primary" @click="perinfodialogVisibleClose">关闭</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="添加外部人员" :visible.sync="addextperdialogVisible" width="23%" :before-close="addextperdialogVisibleClose">
            <el-form :model="extperForm" :rules="experrules" ref="experruleForm" label-width="100px" class="extClFrom">
                <el-form-item label="外部人员：" prop="name">
                    <el-cascader v-model="extperForm.name" :key='cascaderIdx' placeholder="请选择外部人员" :options="extoptions" :props="{ expandTrigger: 'hover' }" @change="handleChange"></el-cascader>
                </el-form-item>

                <div class="btnCen">
                    <el-button style="margin-top: 12px;" v-if="addextClick" type="primary" :loading="addextClickKing" @click="experSub">提交</el-button>
                     <el-button style="margin-top: 12px;" v-else type="info" :loading="addextClickKing"></el-button>
                </div>
            </el-form>
        </el-dialog>

        <!-- 科室绩效考核指标 -->
        <el-dialog title="添加科室绩效考核指标" :visible.sync="assinddialogVisible" width="90%" top="1vh" :before-close="assinddialogVisibleClose">
            <el-form :model="accsiModel" ref="accsiRef" :inline="true" label-width="220px" :rules="accsiRules">
                <div>
                    <div class="titBol">住院工作量</div>
                    <div class="conInp">
                        <el-form-item label="管床患者平均住院日：" prop="avlebed">
                            <el-select v-model="accsiModel.avlebed" placeholder="请选择管床患者平均住院日" clearable>
                                <el-option v-for="item in assOption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="床位使用率：" prop="bedusg">
                            <el-select v-model="accsiModel.bedusg" placeholder="请选择床位使用率" clearable>
                                <el-option v-for="item in assOption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div>
                    <div class="titBol">运营指标</div>
                    <div class="conInp">
                        <el-form-item label="DRG住院次均费用：" prop="drgvist">
                            <el-select v-model="accsiModel.drgvist" placeholder="请选择DRG住院次均费用" clearable>
                                <el-option v-for="item in assOption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="药占比：" prop="drugpro">
                            <el-select v-model="accsiModel.drugpro" placeholder="请选择药占比" clearable>
                                <el-option v-for="item in assOption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="耗占比：" prop="conpro">
                            <el-select v-model="accsiModel.conpro" placeholder="请选择耗占比" clearable>
                                <el-option v-for="item in assOption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div>
                    <div class="titBol">质量控制指标</div>
                    <div class="conInp">
                        <el-form-item label="抗微生物药物使用强度：" prop="intandru">
                            <el-select v-model="accsiModel.intandru" placeholder="请选择抗微生物药物使用强度" clearable>
                                <el-option v-for="item in assOption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="医疗核心制度落实情况：" prop="imcomesys">
                            <el-select v-model="accsiModel.imcomesys" placeholder="请选择医疗核心制度落实情况" clearable>
                                <el-option v-for="item in assOption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="医务人员手卫生依从率：" prop="meshyper">
                            <el-select v-model="accsiModel.meshyper" placeholder="请选择医务人员手卫生依从率" clearable>
                                <el-option v-for="item in assOption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="限制性使用抗菌药物病原送检：" prop="reusanpate">
                            <el-select v-model="accsiModel.reusanpate" placeholder="请选择限制性使用抗菌药物病原送检" clearable>
                                <el-option v-for="item in assOption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="特殊使用抗菌药物病原送检率：" prop="spandrpadera">
                            <el-select v-model="accsiModel.spandrpadera" placeholder="请选择特殊使用抗菌药物病原送检率" clearable>
                                <el-option v-for="item in assOption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div>
                    <div class="titBol">患者满意度</div>
                    <div class="conInp">
                        <el-form-item label="问卷调研分数：" prop="qususc">
                            <el-select v-model="accsiModel.qususc" placeholder="请选择问卷调研分数" clearable>
                                <el-option v-for="item in assOption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>

                <el-form-item style="width:100%;text-align:center;">
                    <div class="btnCen">
                        <el-button style="margin-top: 12px;" v-if="assisClick" type="primary" :loading="assisClickKing" @click="assisSub">提交</el-button>
                         <el-button style="margin-top: 12px;" v-else type="info" :loading="assisClickKing"></el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>

    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    data() {
        let validOrgan = (rule, value, callback) => {
            // 获取选中节点数组
            let arr = [],
                arrTwo = [];
            if (this.$refs.doctree) {
                arr = this.$refs.doctree[0].getCheckedKeys();
            }
            if (this.$refs.nurtree) {
                arrTwo = this.$refs.nurtree[0].getCheckedKeys();
            }

            if (arr.length <= 0 && arrTwo.length <= 0) {
                callback(new Error("请选择附加职责"));
            } else {
                callback();
            }
        };
        return {
            perpostType: "",
            st: 1,
            limit: 5,
            count: "",
            onest: 1,
            onelimit: 5,
            onecount: "",
            activeName: "first",
            subextClick: true,
            subextClickKing: false,
            assOption: [
                {
                    label: "90分",
                    value: "90",
                },
                {
                    label: "100分",
                    value: "100",
                },
                {
                    label: "120分",
                    value: "120",
                },
            ],
            assinddialogVisible: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 24 * 3600 * 1000;
                },
            },
            saveShow: false,
            importExpClick: true,
            importExpClickTwo: false,
            seartype: "",
            taroptions: [],
            nextClick: true,
            nextClickKing: false,
            saveextClick: true,
            saveextClickKing: false,
            assisClick: true,
            assisClickKing: false,
            addextClick: true,
            addextClickKing: false,
            cascaderIdx: 0,
            depOptions: [],
            departoptions: [],
            accsiModel: {
                avlebed: "100",
                bedusg: "100",
                drgvist: "100",
                drugpro: "100",
                conpro: "100",
                intandru: "100",
                imcomesys: "100",
                meshyper: "100",
                reusanpate: "100",
                spandrpadera: "100",
                qususc: "100",
            },
            departmentUserName: "",
            accsiRules: {
                qususc: [
                    {
                        required: true,
                        message: "请选择问卷调研分数",
                        trigger: "change",
                    },
                ],
                spandrpadera: [
                    {
                        required: true,
                        message: "请选择特殊使用抗菌药物病原送检率",
                        trigger: "change",
                    },
                ],
                reusanpate: [
                    {
                        required: true,
                        message: "请选择限制性使用抗菌药物病原送检",
                        trigger: "change",
                    },
                ],
                meshyper: [
                    {
                        required: true,
                        message: "请选择医务人员手卫生依从率",
                        trigger: "change",
                    },
                ],
                imcomesys: [
                    {
                        required: true,
                        message: "请选择医疗核心制度落实情况",
                        trigger: "change",
                    },
                ],
                intandru: [
                    {
                        required: true,
                        message: "请选择抗微生物药物使用强度",
                        trigger: "change",
                    },
                ],
                conpro: [
                    {
                        required: true,
                        message: "请选择耗占比",
                        trigger: "change",
                    },
                ],
                drugpro: [
                    {
                        required: true,
                        message: "请选择药占比",
                        trigger: "change",
                    },
                ],
                drgvist: [
                    {
                        required: true,
                        message: "请选择DRG住院次均费用",
                        trigger: "change",
                    },
                ],
                bedusg: [
                    {
                        required: true,
                        message: "请选择床位使用率",
                        trigger: "change",
                    },
                ],
                avlebed: [
                    {
                        required: true,
                        message: "请选择管床患者平均住院日",
                        trigger: "change",
                    },
                ],
            },
            AddDateModel: {
                departme: "", //所属部门：
                emptype: "", //职工类型：
                post: "", //职务
                title: "", //职称
                jobnum: "100", //工号
                name: "", //姓名
                idcard: "", //身份证
                sex: "", //性别
                age: "", //年龄
                education: "", //学历
                polout: "", //政治面貌：
                autstr: "", //编制：
                ganpost: [], //岗位：
                bralear: [], //学科
                lengthOfService: "",
            },
            postValue: "",
            perShow: false,
            nexShow: true,
            nexTwoShow: true,
            defaultProps: {
                children: "children",
                label: "name",
            },
            basscoList: [],
            extperForm: {
                name: "",
            },
            extoptions: [],
            addextperdialogVisible: false,
            planoptions: [],
            worktabData: [
                {
                    name: "工作量",
                },
            ],
            workpagNum: 1,
            workpagSize: 10,
            worktotal: 1,
            perinfodialogVisible: false,
            workloaddialogVisible: false,
            departmentName: "",
            active: 0,
            stepParams: [
                { title: "附加职责", index: 0, id: "5", workType: "" },
                { title: "专项奖励指标", index: 1, id: "2", workType: "" },
                {
                    title: "单项补助及奖惩指标",
                    index: 2,
                    id: "3",
                    workType: "",
                },
                { title: "个人考勤指标", index: 3, id: "4", workType: "" },
            ],
            perfilldialogVisible: false,
            props: { multiple: true },
            perRules: {
                //     addValue: [
                //         {
                //             required: true,
                //             validator: validOrgan,
                //             trigger: "change",
                //         },
                //     ],
            },
            poValue: "",
            perforModel: {
                basData: [],
                addValue: [],
                speVal: [], // 专项奖励
                indVal: [], // 单项补助及奖惩指标
                addVal: [], // 附加职责
                perVal: [], // 个人考勤指标
            },
            ourforModel: {
                workList: [], //工作量
                trafficVal: [], //业务量
                workMedList: [],
                workProList: [],
            },
            checkKeys: [],
            postOptions: [],
            filStatus: "",
            filName: "",
            depfilldialogVisible: false,
            monthtit: "新建月度填报",
            diaBtn: "新建",
            addDepClick: true,
            addDepClickKing: false,
            AdddialogVisible: false,
            searDate: "",
            depoptions: [],
            searDepVal: "",
            // 当前页数
            pageNum: 1,
            fillMonth: "",
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            userFillInId: "",
            // 表格数据
            tableData: [],
            deptabData: [
                {
                    name: "测试",
                },
            ],
            deppagNum: 1,
            deppagSize: 10,
            deptotal: 1,
            statusoptions: [
                {
                    value: "0",
                    label: "已完成",
                },
                {
                    value: "1",
                    label: "未完成",
                },
            ],
            formModel: {
                departmentId: "",
                date: "",
                remark: "",
                allplan: "",
                position: "",
                name: "",
            },
            experrules: {
                name: [
                    {
                        required: true,
                        message: "请选择外部人员",
                        trigger: "change",
                    },
                ],
            },
            formRules: {
                name: [
                    {
                        required: true,
                        message: "请填写绩效数据填报名称",
                        trigger: "blur",
                    },
                ],
                allplan: [
                    {
                        required: true,
                        message: "请选择分配方案",
                        trigger: "change",
                    },
                ],
                position: [
                    {
                        required: true,
                        message: "请选择填报数据类型",
                        trigger: "change",
                    },
                ],
                departmentId: [
                    {
                        required: true,
                        message: "请选择科室",
                        trigger: "change",
                    },
                ],
                date: [
                    {
                        required: true,
                        message: "请选择数据月份",
                        trigger: "change",
                    },
                ],
            },
            shareScopeEnd: [],
            fillInId: "", //科室绩效数据填报id
            fillperId: "", //科室人员填报绩效数据id
            allocationSchemeId: "", //科室绩效方案id
            perDetailList: [],
            searYear: "",
            searMonth: "",
            wardId: "",
            echoShow: false,
            baroptions: [],
            empoptions: [],
            postoptions: [],
            autoptions: [],
            educationRange: [],
            titleRange: [],
            poloptions: [],
            lengthOfServiceoptions: [],
            sexoptions: [
                {
                    value: 1,
                    label: "男",
                },
                {
                    value: 2,
                    label: "女",
                },
            ],
            depValue: "",
            belongDepartmentId: "",
            currYea: "",
            currMon: "",
            cesuanExpClick: true,
            cesuanExpClickTwo: false,
            downurl: this.GLOBAL + "/openApi/hosDepartmentUser/importExcel",
            fileList: [], // 上传文件的列表
            limitUpload: 1, //最大允许上传个数
            dataType: "",
        };
    },
    computed: {
        token() {
            return {
                Authorization:
                    "Bearer " + window.sessionStorage.getItem("token"),
            };
        },
    },
    created() {
        this.getDay();
        this.findDepartment();
        this.fillList();
    },
    watch: {
        extoptions(newVal) {
            this.cascaderIdx++;
        },
    },
    methods: {
        uploading() {
            this.importExpClick = false;
            this.importExpClickTwo = true;
        },
        // 导入
        successFile(res) {
            this.importExpClick = true;
            this.importExpClickTwo = false;
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.$message.success("导入成功");
                this.listhosDepartmentUser();
                this.$refs.uploadone.clearFiles();
            } else {
                this.$refs.uploadone.clearFiles();
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async perexportExcel() {
            this.cesuanExpClick = false;
            this.cesuanExpClickTwo = true;
            let params = {
                yearMonth: this.fillMonth + "月", //填报的月份
                fillInId: this.fillInId, //科室绩效数据填报id
            };
            let res = await this.$axios.perexportExcel(params);
            // console.log(res);
            if (res.status == 200) {
                if (res.data.code == 401) {
                    this.$router.push("/login");
                } else if (res.code == 500) {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                } else if (res.status == 200) {
                    let updateTime = this.addDate();
                    let blob = new Blob([res.data], {
                        type: "application/x-xls",
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    //配置下载的文件名
                    link.download = this.fillMonth + "月数据详情" + ".xls";
                    link.click();
                    this.cesuanExpClick = true;
                    this.cesuanExpClickTwo = false;
                }
            }
        },
        //获取当前年月日
        addDate() {
            const nowDate = new Date();
            const date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                date: nowDate.getDate(),
            };
            const newmonth = date.month > 10 ? date.month : "0" + date.month;
            const day = date.date > 10 ? date.date : "0" + date.date;
            let updateTime = date.year + "-" + newmonth + "-" + day;
            return updateTime;
        },
        indexMethods(index) {
            return (this.st - 1) * this.limit + index + 1;
        },
        indexOneMethods(index) {
            return (this.onest - 1) * this.onelimit + index + 1;
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        // 工作量提交
        nextSub() {
            this.$refs.performRef.validate(async (valid) => {
                if (valid) {
                    this.subextClick = false;
                    this.subextClickKing = true;
                    this.addWorkload();
                } else {
                    this.$message({
                        message: "请将指标都填写完毕后再提交",
                        type: "error",
                    });
                }
            });
        },
        async addWorkload() {
            // console.log(this.ourforModel.workList);
            // console.log(this.ourforModel.trafficVal);
            let totArr = [
                ...this.ourforModel.workList,
                ...this.ourforModel.trafficVal,
            ];
            // console.log(totArr);
            let array = {
                performanceFillInUserOtherList: [],
            };
            // 病例病种和医疗服务项目的
            for (let i = 0; i < totArr.length; i++) {
                // console.log(totArr[i]);
                array.userFillInId = this.fillperId; //用户填报id
                for (
                    let j = 0;
                    j < totArr[i].performanceAllocationSchemeDetailList.length;
                    j++
                ) {
                    if (totArr[i].name == "业务量") {
                        array.performanceFillInUserOtherList.push({
                            thirdLibraryId:
                                totArr[i].performanceAllocationSchemeDetailList[
                                    j
                                ].thirdLibraryId,
                            score: totArr[i]
                                .performanceAllocationSchemeDetailList[j]
                                .performanceIndicatorThirdLibrary.score,
                        });
                    } else {
                        array.performanceFillInUserOtherList.push({
                            thirdLibraryId:
                                totArr[i].performanceAllocationSchemeDetailList[
                                    j
                                ].thirdLibraryId,
                            score: totArr[i]
                                .performanceAllocationSchemeDetailList[j].score,
                        });
                    }

                    array.allocationSchemeId = this.allocationSchemeId;
                }
            }
            // console.log(array);
            let { data: res } = await this.$axios.addWorkload(array);
            // console.log(res);
            this.subextClick = true;
            this.subextClickKing = false;
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                this.$message({
                    message: res.msg,
                    type: "success",
                });
                this.workloaddialogVisibleClose();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        assisSub() {
            this.$refs.accsiRef.validate(async (valid) => {
                if (valid) {
                    this.assisClick = false;
                    this.assisClickKing = true;

                    //  this.assisClick = true;
                    // this.assisClickKing = false;
                }
            });
        },
        // 点击科室绩效考核指标
        assindito() {
            this.assinddialogVisible = true;
        },
        assinddialogVisibleClose() {
            this.assinddialogVisible = false;
        },
        getDay() {
            let date = new Date(),
                year = date.getFullYear(), //获取完整的年份(4位)
                month = date.getMonth(), //获取当前月份(0-11,0代表1月)
                strDate = date.getDate(); // 获取当前日(1-31)
            if (month < 10) month = `0${month}`; // 如果月份是个位数，在前面补0
            if (strDate < 10) strDate = `0${strDate}`; // 如果日是个位数，在前面补0

            //   return `${year}-${month}-${strDate}`
            (this.currYea = `${year}`), (this.currMon = `${month}`);
        },
        async perdindBy() {
            let data = {
                status: "0",
                deleteFlag: "0", //删除标识（0正常  1已删除）
                name: "",
                indicatorLibraryType: "1", //指标类型（0基本指标 1工作量指标 2专项奖励指标 3单项补助及奖惩指标  4个人考勤指标  5附加职责）
                id: "",
            };
            let res = await this.$axios.perdindBy(data);
            // console.log(res);
            if (res.status == "200") {
                // console.log(res.data);
                this.taroptions = res.data;
            }
        },
        getPidMenuList(pid) {
            let cid_list = [];
            this.depOptions.forEach((item, index) => {
                if (item.value == pid) {
                    cid_list = [item.value];
                    return false;
                } else {
                    if (item.children) {
                        let newCid_list = [item.value];
                        let list = nodefun(item.children, pid, newCid_list);
                        if (list) {
                            cid_list = list;
                        }
                    }
                }
            });
            // 递归函数
            function nodefun(newVal, newId, newCid_list) {
                let flag = false;
                newVal.forEach((j) => {
                    if (j.value == newId) {
                        newCid_list.push(j.value);
                        flag = true;
                    } else {
                        if (j.children) {
                            let cid_list = JSON.parse(
                                JSON.stringify(newCid_list)
                            );
                            cid_list.push(j.value);
                            let list = nodefun(j.children, newId, cid_list);
                            if (list) {
                                newCid_list = list;
                                flag = true;
                            }
                        }
                    }
                });
                if (flag) {
                    return newCid_list;
                }
            }
            return cid_list;
        },
        async postCan(val) {
            let data = {
                dictType: val,
                status: "0",
            };
            let res = await this.$axios.findBySys(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                if (val == "hos_department_user_position") {
                    this.postoptions = res.data;
                } else if (val == "hos_authorized_strength") {
                    this.autoptions = res.data;
                } else if (
                    val == "hos_department_user_educational_background"
                ) {
                    this.educationRange = res.data;
                } else if (val == "hos_department_user_professional_title") {
                    this.titleRange = res.data;
                } else if (val == "hos_employee_type") {
                    this.empoptions = res.data;
                } else if (val == "hos_political_outlook") {
                    this.poloptions = res.data;
                } else if (val == "hos_department_user_length_of_service") {
                    this.lengthOfServiceoptions = res.data;
                }
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 学科
        async hosDisciplines() {
            let data = {
                deleteFlag: "0",
            };
            let res = await this.$axios.hosDisciplines(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                this.baroptions = res.data;
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        nurindiChange(data, node) {
            let array = [];
            if (this.$refs.doctree) {
                this.$refs.doctree[0].setCheckedKeys([]);
            }

            for (let i = 0; i < node.checkedNodes.length; i++) {
                array.push({
                    thirdLibraryId: node.checkedNodes[i].thirdLibraryId,
                    score: data.score,
                    checkFlag: "1", //是否选中指标的标识（0未选择  1选择）
                });
            }
            this.perforModel.addValue = array;
        },
        docindiChange(data, node) {
            let array = [];
            if (this.$refs.nurtree) {
                this.$refs.nurtree[0].setCheckedKeys([]);
            }
            // console.log(data.score);
            // console.log(node);
            for (let i = 0; i < node.checkedNodes.length; i++) {
                // console.log(node.checkedNodes[i]);
                array.push({
                    thirdLibraryId: node.checkedNodes[i].thirdLibraryId,
                    score: node.checkedNodes[i].score,
                    checkFlag: "1", //是否选中指标的标识（0未选择  1选择）
                });
            }
            this.perforModel.addValue = array;
            // console.log(array);
        },
        searcDate(val) {
            if (val) {
                this.searDate = val;
                this.searYear = this.searDate.split("-")[0];
                this.searMonth = this.searDate.split("-")[1];
            } else {
                this.searYear = "";
                this.searMonth = "";
            }
            this.pageNum = 1;
            this.fillList();
        },
        depSearch(val) {
            this.searDepVal = val;
            this.pageNum = 1;
            this.fillList();
        },
        handleChange(value) {
            this.extperForm.name = value[2];
        },
        experSub() {
            this.$refs.experruleForm.validate(async (valid) => {
                if (valid) {
                    this.addextClick = false;
                    this.addextClickKing = true;
                    this.addOtherUser();
                }
            });
        },
        async addOtherUser() {
            let data = _qs.stringify({
                fillInId: this.fillInId, //科室人员填报绩效数据id
                departmentUserId: this.extperForm.name, //审核状态（0正常  1审核未通过）
                dataType: this.dataType,
            });
            let { data: res } = await this.$axios.addOtherUser(data);
            // console.log(res);
            this.addextClick = true;
            this.addextClickKing = false;
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.perfillList();
                this.addextperdialogVisibleClose();
                this.$message({
                    message: res.msg,
                    type: "success",
                });
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 添加外部人员
        addextper() {
            this.addextperdialogVisible = true;
            this.extoptions = [];
            this.optionselect();
        },
        async checkFillInUsers() {
            let data = _qs.stringify({
                fillInId: this.fillInId, //科室绩效数据填报id
                belongDepartmentId: this.belongDepartmentId, //科室人员所属科室id
            });
            let { data: res } = await this.$axios.checkFillInUsers(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.perfillList();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async optionselect() {
            let data = {
                dataType: "0", //科室绩效专用数据类型（ 0岗位职责 ）
            };
            let { data: res } = await this.$axios.optionselect(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);

                for (let i = 0; i < this.departoptions.length; i++) {
                    if (this.departmentName !== this.departoptions[i].name) {
                        this.extoptions.push({
                            label: this.departoptions[i].name,
                            value: this.departoptions[i].id,
                            level: 1,
                            children: [],
                        });
                    }
                }

                for (let i = 0; i < res.data.length; i++) {
                    for (let j = 0; j < this.extoptions.length; j++) {
                        this.extoptions[j].children.push({
                            label: res.data[i].dictName,
                            value: res.data[i].dictType,
                            level: 2,
                            children: [],
                        });
                    }
                }

                this.hosfind();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async hosfind() {
            for (let i = 0; i < this.extoptions.length; i++) {
                for (let j = 0; j < this.extoptions[i].children.length; j++) {
                    let data = {
                        deleteFlag: "0",
                        departmentId: this.extoptions[i].value, //所属科室id
                        name: "", //姓名
                        inpatientWardId: "", //所属病区id
                        educationalBackground: "", //学历
                        professionalTitle: "", //职称
                        position: "", //岗位
                        lengthOfService: "", //工龄
                        pinyinInitials: "", //人员姓名拼音缩写
                        postType: this.extoptions[i].children[j].value, //岗位职责类型
                    };
                    let res = await this.$axios.hosfindBy(data);
                    if (res.status == 200) {
                        for (let n = 0; n < res.data.length; n++) {
                            if (this.extoptions[i].children.length > 0) {
                                // console.log(res.data[n]);
                                this.extoptions[i].children[j].children.push({
                                    value: res.data[n].id,
                                    label:
                                        res.data[n].workId +
                                        "-" +
                                        res.data[n].name,
                                });
                            }
                        }
                    }
                }
            }
        },
        addextperdialogVisibleClose() {
            this.addextperdialogVisible = false;
            this.$refs.experruleForm.resetFields();
            this.extperForm = {
                name: "",
            };
            this.extoptions = [];
        },
        scoreselect(val) {
            console.log(val);
        },
        depChange(val) {
            if (val) {
                this.formModel.departmentId = val;
                this.perallSchFind();
                this.formModel.allplan = "";
                this.formModel.date = "";
            } else {
                this.planoptions = [];
                this.formModel.allplan = "";
                this.formModel.date = "";
            }
        },
        planChange(val) {
            this.formModel.allplan = val;
        },
        posChange(val) {
            // console.log(val);
            this.formModel.position = val;
        },
        dateVal(val) {
            // console.log(val);
            this.formModel.date = val;
        },
        perinfodialogVisibleClose() {
            this.perinfodialogVisible = false;
            this.departmentUserName = "";
            this.depValue = "";
            this.postValue = "";
            this.basscoList = [];
            this.AddDateModel = {
                departme: "", //所属部门：
                emptype: "", //职工类型：
                post: "", //职务
                title: "", //职称
                jobnum: "", //工号
                name: "", //姓名
                idcard: "", //身份证
                sex: "", //性别
                age: "", //年龄
                education: "", //学历
                polout: "", //政治面貌：
                autstr: "", //编制：
                ganpost: [], //岗位：
                bralear: [], //学科
                lengthOfService: "",
            };
        },
        // 工作量数据查看
        vieWorkDat() {},
        viwWorkIndq(row) {},
        // 工作量指标查看
        viwWorkInd(row) {
            // console.log(row);
            this.departmentUserName = row.departmentUserName;
            this.fillperId = row.id;
            this.onest = 1;
            // onelimit: 5,

            //  工作量指标填报状态（0未填报完  1已填报完毕）
            // console.log(row);
            if (row.workloadLibraryStatus == "0") {
                // 未填报
                this.findWorkload();
            } else if (row.workloadLibraryStatus == "1") {
                // 已填报
                this.findWorkloadFillInData();
            }

            this.workloaddialogVisible = true;
            this.perdindBy();
        },
        // 已填报回显
        async findWorkloadFillInData() {
            let workTwoArr = [];
            let workMedArr = [];
            let workProArr = [];
            this.ourforModel.trafficVal = [];
            let data = {
                userFillInId: this.fillperId, //科室人员填报绩效数据id
                allocationSchemeId: this.allocationSchemeId, //科室绩效方案id
            };
            let { data: res } = await this.$axios.findWorkloadFillInData(data);
            // console.log(res.data.workloadLibraryList);
            for (let i = 0; i < res.data.workloadLibraryList.length; i++) {
                // console.log(res.data.workloadLibraryList[i]);
                res.data.workloadLibraryList[
                    i
                ].performanceAllocationSchemeDetailList =
                    res.data.workloadLibraryList[
                        i
                    ].performanceFillInUserOtherList;
                if (res.data.workloadLibraryList[i].name == "业务量") {
                    // console.log(res.data.workloadLibraryList[i]);
                    this.ourforModel.trafficVal.push(
                        res.data.workloadLibraryList[i]
                    );
                } else {
                    workTwoArr.push(res.data.workloadLibraryList[i]);
                    if (res.data.workloadLibraryList[i].name == "病历病种") {
                        workMedArr.push(res.data.workloadLibraryList[i]);
                    } else if (
                        res.data.workloadLibraryList[i].name == "医疗服务项目"
                    ) {
                        workProArr.push(res.data.workloadLibraryList[i]);
                    }
                }

                if (res.data.workloadLibraryList[i].name == "病历病种") {
                    this.count =
                        res.data.workloadLibraryList[
                            i
                        ].performanceAllocationSchemeDetailList.length;
                } else if (
                    res.data.workloadLibraryList[i].name == "医疗服务项目"
                ) {
                    this.onecount =
                        res.data.workloadLibraryList[
                            i
                        ].performanceAllocationSchemeDetailList.length;
                }
            }
            this.ourforModel.workList = workTwoArr;
            this.ourforModel.workMedList = workMedArr;
            this.ourforModel.workProList = workProArr;
            this.activChange();
            // console.log(this.ourforModel.workList);
            // console.log(this.ourforModel.trafficVal);
        },
        // 未填报回显
        async findWorkload() {
            let workArr = [];
            this.ourforModel.trafficVal = [];
            let data = {
                id: this.allocationSchemeId, //方案id
            };
            let { data: res } = await this.$axios.findWorkload(data);
            // console.log(res.data.workloadLibraryList);
            for (let i = 0; i < res.data.workloadLibraryList.length; i++) {
                // console.log(res.data.workloadLibraryList[i]);
                if (res.data.workloadLibraryList[i].name == "业务量") {
                    for (
                        let m = 0;
                        m <
                        res.data.workloadLibraryList[i]
                            .performanceAllocationSchemeDetailList.length;
                        m++
                    ) {
                        res.data.workloadLibraryList[
                            i
                        ].performanceAllocationSchemeDetailList[
                            m
                        ].performanceIndicatorThirdLibrary.score = "0";
                    }
                    this.ourforModel.trafficVal.push(
                        res.data.workloadLibraryList[i]
                    );
                } else {
                    workArr.push(res.data.workloadLibraryList[i]);
                }

                if (res.data.workloadLibraryList[i].name == "病历病种") {
                    this.count =
                        res.data.workloadLibraryList[
                            i
                        ].performanceAllocationSchemeDetailList.length;
                } else if (
                    res.data.workloadLibraryList[i].name == "医疗服务项目"
                ) {
                    this.onecount =
                        res.data.workloadLibraryList[
                            i
                        ].performanceAllocationSchemeDetailList.length;
                }
            }
            this.ourforModel.workList = workArr;
            this.activChange();
            // console.log(this.ourforModel.workList);
        },
        activChange() {
            if (this.ourforModel.workProList.length > 0) {
                this.activeName = "first";
            } else if (this.ourforModel.workMedList.length > 0) {
                this.activeName = "second";
            } else if (this.ourforModel.trafficVal.length > 0) {
                this.activeName = "third";
            }
            // console.log(this.ourforModel);
            // console.log(this.activeName);
        },
        workloaddialogVisibleClose() {
            this.workloaddialogVisible = false;
            this.activeName = "first";
            this.$refs.performRef.resetFields();
            this.perfillList();
            this.departmentUserName = "";
            this.st = 1;
            this.onest = 1;
        },
        // 个人信息查看
        perInfo(row) {
            this.perinfodialogVisible = true;
            this.wardId = row.departmentUserId;
            this.departmentUserName = row.departmentUserName;
            // this.hosfindByKey();
            // 学科
            this.hosDisciplines();
            this.getBasicLibrary(row);
        },
        async getBasicLibrary(row) {
            let data = {
                id: row.id, //科室人员填报绩效数据id
                allocationSchemeId: this.allocationSchemeId, //方案id
            };
            let { data: res } = await this.$axios.getBasicLibrary(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                // console.log(res.data.basicLibraryList);
                let basList = {
                        title: "", //职称
                        education: "", //学历
                        seniority: "", //工龄
                        duties: "", //职务
                        poloutlook: "", //政治面貌
                        organization: "", //编制
                        ployetype: "", //职工类型
                        post: "", //岗位
                    },
                    basKonList = [];
                for (let m = 0; m < res.data.basicLibraryList.length; m++) {
                    // console.log(res.data.basicLibraryList[m]);

                    if (
                        res.data.basicLibraryList[m]
                            .performanceFillInUserOtherList.length > 0
                    ) {
                        for (
                            let n = 0;
                            n <
                            res.data.basicLibraryList[m]
                                .performanceFillInUserOtherList.length;
                            n++
                        ) {
                            basKonList.push({
                                name: res.data.basicLibraryList[m].name,
                                score: res.data.basicLibraryList[m]
                                    .performanceFillInUserOtherList[n].score,
                            });
                        }
                    }
                }
                // console.log(basKonList);

                basKonList.map((item) => {
                    if (item.name == "工龄（年）") {
                        basList.seniority = item.score;
                    }

                    if (item.name == "职称") {
                        basList.title = item.score;
                    }

                    if (item.name == "政治面貌") {
                        basList.poloutlook = item.score;
                    }

                    if (item.name == "编制") {
                        basList.organization = item.score;
                    }

                    if (item.name == "职工类型") {
                        basList.ployetype = item.score;
                    }

                    if (item.name == "岗位") {
                        basList.post = item.score;
                    }

                    if (item.name == "学历") {
                        basList.education = item.score;
                    }

                    if (item.name == "职务") {
                        basList.duties = item.score;
                    }
                });
                this.basscoList = basList;
                // console.log(basList);
                // console.log(this.basscoList);
                // 回显个人信息
                this.reayJob = res.data.hosDepartmentUser.workId;
                this.AddDateModel.jobnum = res.data.hosDepartmentUser.workId;
                this.AddDateModel.name = res.data.hosDepartmentUser.name;
                this.AddDateModel.idcard = res.data.hosDepartmentUser.idNumber;
                this.AddDateModel.age = res.data.hosDepartmentUser.dateOfBirth;
                this.AddDateModel.sex = res.data.hosDepartmentUser.sex;
                this.AddDateModel.education =
                    res.data.hosDepartmentUser.educationalBackground;
                this.AddDateModel.polout =
                    res.data.hosDepartmentUser.politicalOutlook;
                this.AddDateModel.autstr =
                    res.data.hosDepartmentUser.authorizedStrength;
                this.AddDateModel.emptype =
                    res.data.hosDepartmentUser.employeeType;
                this.AddDateModel.post = res.data.hosDepartmentUser.post;

                this.AddDateModel.text = res.data.hosDepartmentUser.remark;
                this.AddDateModel.title =
                    res.data.hosDepartmentUser.professionalTitle;
                this.AddDateModel.education =
                    res.data.hosDepartmentUser.educationalBackground;
                this.AddDateModel.lengthOfService =
                    res.data.hosDepartmentUser.lengthOfService;
                this.AddDateModel.bralear = [];
                this.AddDateModel.ganpost = [];
                this.postValue = [];

                let array = [];
                // 回显岗位
                if (res.data.hosDepartmentUser.postResponsibility) {
                    let postRes =
                        res.data.hosDepartmentUser.postResponsibility.split(
                            ","
                        );
                    for (let i = 0; i < postRes.length; i++) {
                        array.push([
                            res.data.hosDepartmentUser.postType,
                            postRes[i],
                        ]);
                    }
                }
                // console.log(array);
                this.AddDateModel.ganpost =
                    res.data.hosDepartmentUser.postResponsibility;
                // console.log(res.data.hosDepartmentUser.postResponsibility);
                this.postValue = array;
                this.shareScopeEnd = array;

                this.postType = res.data.hosDepartmentUser.postType;

                if (res.data.hosDepartmentUser.disciplineId) {
                    let disRes =
                        res.data.hosDepartmentUser.disciplineId.split(",");
                    for (let i = 0; i < disRes.length; i++) {
                        this.AddDateModel.bralear.push(Number(disRes[i]));
                    }
                }

                this.depValue = this.getPidMenuList(
                    Number(res.data.hosDepartmentUser.inpatientWardId)
                );
                this.AddDateModel.departme = this.depValue;
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        async hosfindByKey() {
            let data = {
                id: this.wardId,
            };
            let res = await this.$axios.hosfindByKey(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.status == 200) {
                // console.log(res.data);
                this.reayJob = res.data.workId;
                this.AddDateModel.jobnum = res.data.workId;
                this.AddDateModel.name = res.data.name;
                this.AddDateModel.idcard = res.data.idNumber;
                this.AddDateModel.age = res.data.dateOfBirth;
                this.AddDateModel.sex = res.data.sex;
                this.AddDateModel.education = res.data.educationalBackground;
                this.AddDateModel.polout = res.data.politicalOutlook;
                this.AddDateModel.autstr = res.data.authorizedStrength;
                this.AddDateModel.emptype = res.data.employeeType;
                this.AddDateModel.post = res.data.post;

                this.AddDateModel.text = res.data.remark;
                this.AddDateModel.title = res.data.professionalTitle;
                this.AddDateModel.education = res.data.educationalBackground;
                this.AddDateModel.lengthOfService = res.data.lengthOfService;
                this.AddDateModel.bralear = [];
                this.AddDateModel.ganpost = [];
                this.postValue = [];

                let array = [];
                // 回显岗位

                if (res.data.postResponsibility) {
                    let postRes = res.data.postResponsibility.split(",");
                    for (let i = 0; i < postRes.length; i++) {
                        array.push([res.data.postType, postRes[i]]);
                    }
                }
                // console.log(array);
                this.AddDateModel.ganpost = res.data.postResponsibility;
                this.postValue = array;
                this.shareScopeEnd = array;

                this.postType = res.data.postType;

                if (res.data.disciplineId) {
                    let disRes = res.data.disciplineId.split(",");
                    for (let i = 0; i < disRes.length; i++) {
                        this.AddDateModel.bralear.push(Number(disRes[i]));
                    }
                }

                this.depValue = this.getPidMenuList(
                    Number(res.data.inpatientWardId)
                );
                this.AddDateModel.departme = this.depValue;
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        change(e) {
            this.$forceUpdate();
        },
        preste() {
            if (this.active-- <= 0) this.active = 0;
            if (this.echoShow) {
                // 回显
                this.findFillInData();
                if (this.active >= 3) {
                    this.nexTwoShow = false;
                } else {
                    this.nexTwoShow = true;
                }
                if (this.active <= 0) {
                    this.perShow = false;
                } else {
                    this.perShow = true;
                }
            } else {
                this.findFillInData();
                this.findByType();
                if (this.active == "3") {
                    this.saveShow = true;
                } else {
                    this.saveShow = false;
                }
                this.showActi();
            }
        },
        next() {
            // console.log(this.active);
            this.$refs.perform.validate(async (valid) => {
                if (valid) {
                    if (this.active++ > 2) this.active = 0;
                    if (this.echoShow) {
                        // 回显
                        this.findFillInData();
                        this.nexShow = false;
                        // console.log(this.active);
                        // 最后一步的时候修改直接关闭页面
                        if (this.active == 3) {
                            this.nexTwoShow = false;
                        }
                        if (this.active <= 0) {
                            this.perShow = false;
                        } else {
                            this.perShow = true;
                        }
                    } else {
                        this.findByType();
                        if (this.active == "3") {
                            this.saveShow = true;
                        } else {
                            this.saveShow = false;
                        }
                        this.showActi();
                    }
                    this.formData();
                }
            });
        },
        tabSwitchBtn(val) {
            if (this.echoShow) {
                // 回显
                this.$refs.perform.validate(async (valid) => {
                    if (valid) {
                        this.active = val.index;
                        this.findFillInData();
                        this.showActi();
                    }
                });
            } else {
                this.$message({
                    message: "请按照步骤进行填写",
                    type: "error",
                });
            }
        },
        showActi() {
            if (this.active >= 3) {
                this.nexShow = false;
            } else {
                this.nexShow = true;
            }
            if (this.active <= 0) {
                this.perShow = false;
            } else {
                this.perShow = true;
            }
        },
        formData() {
            // console.log(this.perforModel.basData);
            for (let i = 0; i < this.stepParams.length; i++) {
                if (this.active == this.stepParams[i].index) {
                    if (this.stepParams[i].title == "个人考勤指标") {
                        let indArr = [],
                            scarray = [];
                        for (
                            let n = 0;
                            n < this.perforModel.basData.length;
                            n++
                        ) {
                            for (
                                let m = 0;
                                m <
                                this.perforModel.basData[n]
                                    .performanceAllocationSchemeDetailList
                                    .length;
                                m++
                            ) {
                                if (
                                    this.perforModel.basData[n]
                                        .performanceAllocationSchemeDetailList[
                                        m
                                    ].characteristic == "3"
                                ) {
                                    // 评分维度（0差  1一般  2优秀）
                                    let sionVal =
                                        this.perforModel.basData[n]
                                            .performanceAllocationSchemeDetailList[
                                            m
                                        ].ratingDimension;
                                    let scorVal;
                                    if (sionVal == "0") {
                                        scorVal =
                                            this.perforModel.basData[n]
                                                .performanceAllocationSchemeDetailList[
                                                m
                                            ].poorScore;
                                    } else if (sionVal == "1") {
                                        scorVal =
                                            this.perforModel.basData[n]
                                                .performanceAllocationSchemeDetailList[
                                                m
                                            ].generalScore;
                                    } else if (sionVal == "2") {
                                        scorVal =
                                            this.perforModel.basData[n]
                                                .performanceAllocationSchemeDetailList[
                                                m
                                            ].goodScore;
                                    }
                                    if (
                                        this.perforModel.basData[n]
                                            .performanceAllocationSchemeDetailList[
                                            m
                                        ].ratingDimension
                                    ) {
                                        scarray.push({
                                            thirdLibraryId:
                                                this.perforModel.basData[n]
                                                    .performanceAllocationSchemeDetailList[
                                                    m
                                                ].thirdLibraryId,
                                            ratingDimension:
                                                this.perforModel.basData[n]
                                                    .performanceAllocationSchemeDetailList[
                                                    m
                                                ].ratingDimension,
                                            score: scorVal,
                                        });
                                    }
                                } else {
                                    indArr.push({
                                        thirdLibraryId:
                                            this.perforModel.basData[n]
                                                .performanceAllocationSchemeDetailList[
                                                m
                                            ].thirdLibraryId,
                                        score: this.perforModel.basData[n]
                                            .performanceAllocationSchemeDetailList[
                                            m
                                        ].score,
                                    });
                                }
                            }
                        }
                        // console.log(scarray);
                        // console.log(indArr);
                        this.perforModel.indVal = [...indArr, ...scarray];
                        // console.log(this.perforModel.indVal);
                    }
                    if (this.stepParams[i].title == "单项补助及奖惩指标") {
                        let speArr = [],
                            thrSpe = [];
                        for (
                            let n = 0;
                            n < this.perforModel.basData.length;
                            n++
                        ) {
                            for (
                                let m = 0;
                                m <
                                this.perforModel.basData[n]
                                    .performanceAllocationSchemeDetailList
                                    .length;
                                m++
                            ) {
                                if (
                                    this.perforModel.basData[n]
                                        .performanceAllocationSchemeDetailList[
                                        m
                                    ].characteristic == "3"
                                ) {
                                    // 评分维度（0差  1一般  2优秀）

                                    let sionVal =
                                        this.perforModel.basData[n]
                                            .performanceAllocationSchemeDetailList[
                                            m
                                        ].ratingDimension;
                                    let scorVal;
                                    if (sionVal == "0") {
                                        scorVal =
                                            this.perforModel.basData[n]
                                                .performanceAllocationSchemeDetailList[
                                                m
                                            ].poorScore;
                                    } else if (sionVal == "1") {
                                        scorVal =
                                            this.perforModel.basData[n]
                                                .performanceAllocationSchemeDetailList[
                                                m
                                            ].generalScore;
                                    } else if (sionVal == "2") {
                                        scorVal =
                                            this.perforModel.basData[n]
                                                .performanceAllocationSchemeDetailList[
                                                m
                                            ].goodScore;
                                    }
                                    if (
                                        this.perforModel.basData[n]
                                            .performanceAllocationSchemeDetailList[
                                            m
                                        ].ratingDimension
                                    ) {
                                        thrSpe.push({
                                            thirdLibraryId:
                                                this.perforModel.basData[n]
                                                    .performanceAllocationSchemeDetailList[
                                                    m
                                                ].thirdLibraryId,
                                            ratingDimension:
                                                this.perforModel.basData[n]
                                                    .performanceAllocationSchemeDetailList[
                                                    m
                                                ].ratingDimension,
                                            score: scorVal,
                                        });
                                    }
                                } else {
                                    speArr.push({
                                        thirdLibraryId:
                                            this.perforModel.basData[n]
                                                .performanceAllocationSchemeDetailList[
                                                m
                                            ].thirdLibraryId,
                                        score: this.perforModel.basData[n]
                                            .performanceAllocationSchemeDetailList[
                                            m
                                        ].score,
                                    });
                                }
                            }
                        }
                        this.perforModel.speVal = [...thrSpe, ...speArr];
                        // console.log(this.perforModel.speVal);
                    }
                }
            }
            // console.log(this.perforModel.basData);
        },
        submit() {
            this.$refs.perform.validate(async (valid) => {
                if (valid) {
                    this.saveextClick = false;
                    this.saveextClickKing = true;
                    let perArr = [],
                        thrPerArr = [];
                    for (let n = 0; n < this.perforModel.basData.length; n++) {
                        for (
                            let m = 0;
                            m <
                            this.perforModel.basData[n]
                                .performanceAllocationSchemeDetailList.length;
                            m++
                        ) {
                            if (
                                this.perforModel.basData[n]
                                    .performanceAllocationSchemeDetailList[m]
                                    .characteristic == "3"
                            ) {
                                // 评分维度（0差  1一般  2优秀）

                                let sionVal =
                                    this.perforModel.basData[n]
                                        .performanceAllocationSchemeDetailList[
                                        m
                                    ].ratingDimension;
                                let scorVal;
                                if (sionVal == "0") {
                                    scorVal =
                                        this.perforModel.basData[n]
                                            .performanceAllocationSchemeDetailList[
                                            m
                                        ].poorScore;
                                } else if (sionVal == "1") {
                                    scorVal =
                                        this.perforModel.basData[n]
                                            .performanceAllocationSchemeDetailList[
                                            m
                                        ].generalScore;
                                } else if (sionVal == "2") {
                                    scorVal =
                                        this.perforModel.basData[n]
                                            .performanceAllocationSchemeDetailList[
                                            m
                                        ].goodScore;
                                }
                                if (
                                    this.perforModel.basData[n]
                                        .performanceAllocationSchemeDetailList[
                                        m
                                    ].ratingDimension
                                ) {
                                    thrPerArr.push({
                                        thirdLibraryId:
                                            this.perforModel.basData[n]
                                                .performanceAllocationSchemeDetailList[
                                                m
                                            ].thirdLibraryId,
                                        ratingDimension:
                                            this.perforModel.basData[n]
                                                .performanceAllocationSchemeDetailList[
                                                m
                                            ].ratingDimension,
                                        score: scorVal,
                                    });
                                }
                            } else {
                                perArr.push({
                                    thirdLibraryId:
                                        this.perforModel.basData[n]
                                            .performanceAllocationSchemeDetailList[
                                            m
                                        ].thirdLibraryId,
                                    score: this.perforModel.basData[n]
                                        .performanceAllocationSchemeDetailList[
                                        m
                                    ].score,
                                });
                            }
                        }
                    }
                    this.perforModel.perVal = [...thrPerArr, ...perArr];

                    let array = [
                        ...this.perforModel.addValue,
                        ...this.perforModel.speVal,
                        ...this.perforModel.indVal,
                        ...this.perforModel.perVal,
                    ];
                    this.perDetailList = array;
                    // console.log(this.perDetailList);
                    this.addTwoBatch(array);
                }
            });
        },
        async addTwoBatch(array) {
            let data = {
                userFillInId: this.fillperId, //列表的id
                allocationSchemeId: this.allocationSchemeId, //绩效方案id
                performanceFillInUserOtherList: this.perDetailList,
            };
            let { data: res } = await this.$axios.addBatch(data);
            this.saveextClick = true;
            this.saveextClickKing = false;
            this.nextClick = true;
            this.nextClickKing = false;
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                this.perfillList();
                this.perfilldialogVisibleClose();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        editsubmit(val) {
            if (val == "下一步") {
                this.$refs.perform.validate(async (valid) => {
                    if (valid) {
                        // if (this.echoShow) {
                        // 回显
                        this.nextClick = false;
                        this.nextClickKing = true;
                        this.dabtlesubmit(val);
                        // } else {
                        //     this.next();
                        // }
                    }
                });
            } else if (val == "修改") {
                this.$refs.perform.validate(async (valid) => {
                    if (valid) {
                        this.saveextClick = false;
                        this.saveextClickKing = true;
                        this.dabtlesubmit(val);
                    }
                });
            }
        },
        dabtlesubmit(val) {
            let perArr = [];

            // console.log(this.perforModel.addValue);
            this.editDat();

            // console.log(this.perforModel.speVal);
            let array = [
                ...this.perforModel.addValue,
                ...this.perforModel.speVal,
                ...this.perforModel.indVal,
                ...this.perforModel.perVal,
            ];

            // console.log(array);
            this.perDetailList = array;
            // console.log(this.perDetailList);
            this.addBatch(array, val);
        },
        editDat() {
            let inarrsep = [],
                arrTwosep = [],
                spearrsep = [],
                repaSpearrArr = [],
                perarrsep = [],
                repPerarArr = [],
                addarrsep = [];
            for (let i = 0; i < this.stepParams.length; i++) {
                if (this.active == this.stepParams[i].index) {
                    if (this.stepParams[i].title == "附加职责") {
                        // console.log(this.perforModel.basData);
                        // console.log(this.perforModel.addValue);
                        if (this.perforModel.addValue.length > 0) {
                            if (this.perforModel.addValue.length <= 0) {
                                for (
                                    let n = 0;
                                    n < this.perforModel.basData.length;
                                    n++
                                ) {
                                    for (
                                        let m = 0;
                                        m <
                                        this.perforModel.basData[n]
                                            .performanceFillInUserOtherList
                                            .length;
                                        m++
                                    ) {
                                        if (
                                            this.perforModel.basData[n]
                                                .performanceFillInUserOtherList[
                                                m
                                            ].checkFlag == "1"
                                        ) {
                                            addarrsep.push({
                                                thirdLibraryId:
                                                    this.perforModel.basData[n]
                                                        .performanceFillInUserOtherList[
                                                        m
                                                    ]
                                                        .performanceIndicatorThirdLibrary
                                                        .id,
                                                score: this.perforModel.basData[
                                                    n
                                                ]
                                                    .performanceFillInUserOtherList[
                                                    m
                                                ].score,
                                                checkFlag: "1",
                                            });
                                        }
                                    }
                                }
                                this.perforModel.addValue = addarrsep;
                                // console.log(addarrsep);
                            }
                        } else {
                            this.perforModel.addValue = [];
                        }
                    }
                    if (this.stepParams[i].title == "个人考勤指标") {
                        this.perforModel.perVal = [];
                        for (
                            let m = 0;
                            m < this.perforModel.basData.length;
                            m++
                        ) {
                            if (
                                !this.perforModel.basData[m]
                                    .performanceFillInUserOtherList
                            ) {
                                this.perforModel.basData[
                                    m
                                ].performanceFillInUserOtherList =
                                    this.perforModel.basData[
                                        m
                                    ].performanceAllocationSchemeDetailList;
                            }
                            for (
                                let n = 0;
                                n <
                                this.perforModel.basData[m]
                                    .performanceFillInUserOtherList.length;
                                n++
                            ) {
                                if (
                                    this.perforModel.basData[m]
                                        .performanceFillInUserOtherList[n]
                                        .characteristic == "3"
                                ) {
                                    let val =
                                        this.perforModel.basData[m]
                                            .performanceFillInUserOtherList[n]
                                            .ratingDimension;
                                    let scoVal;
                                    // （0差  1一般  2优秀）
                                    if (val == "0") {
                                        scoVal =
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].poorScore;
                                    } else if (val == "1") {
                                        scoVal =
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].generalScore;
                                    } else if (val == "2") {
                                        scoVal =
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].goodScore;
                                    }
                                    repPerarArr.push({
                                        score: scoVal,
                                        ratingDimension:
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].ratingDimension,
                                        thirdLibraryId:
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].performanceIndicatorThirdLibrary
                                                .id,
                                    });
                                } else {
                                    perarrsep.push({
                                        score: this.perforModel.basData[m]
                                            .performanceFillInUserOtherList[n]
                                            .score,
                                        thirdLibraryId:
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].performanceIndicatorThirdLibrary
                                                .id,
                                    });
                                }
                            }
                        }
                        this.perforModel.perVal = [
                            ...perarrsep,
                            ...repPerarArr,
                        ];
                        // console.log(this.perforModel.perVal)
                    }
                    if (this.stepParams[i].title == "单项补助及奖惩指标") {
                        this.perforModel.indVal = [];
                        for (
                            let m = 0;
                            m < this.perforModel.basData.length;
                            m++
                        ) {
                            if (
                                !this.perforModel.basData[m]
                                    .performanceFillInUserOtherList
                            ) {
                                this.perforModel.basData[
                                    m
                                ].performanceFillInUserOtherList =
                                    this.perforModel.basData[
                                        m
                                    ].performanceAllocationSchemeDetailList;
                            }
                            for (
                                let n = 0;
                                n <
                                this.perforModel.basData[m]
                                    .performanceFillInUserOtherList.length;
                                n++
                            ) {
                                if (
                                    this.perforModel.basData[m]
                                        .performanceFillInUserOtherList[n]
                                        .characteristic == "3"
                                ) {
                                    let val =
                                        this.perforModel.basData[m]
                                            .performanceFillInUserOtherList[n]
                                            .ratingDimension;
                                    let scoVal;
                                    // （0差  1一般  2优秀）
                                    if (val == "0") {
                                        scoVal =
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].poorScore;
                                    } else if (val == "1") {
                                        scoVal =
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].generalScore;
                                    } else if (val == "2") {
                                        scoVal =
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].goodScore;
                                    }
                                    arrTwosep.push({
                                        score: scoVal,
                                        ratingDimension:
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].ratingDimension,
                                        thirdLibraryId:
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].performanceIndicatorThirdLibrary
                                                .id,
                                    });
                                } else {
                                    inarrsep.push({
                                        score: this.perforModel.basData[m]
                                            .performanceFillInUserOtherList[n]
                                            .score,
                                        thirdLibraryId:
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].performanceIndicatorThirdLibrary
                                                .id,
                                    });
                                }
                            }
                        }

                        this.perforModel.indVal = [...inarrsep, ...arrTwosep];
                        // console.log(arrTwosep);
                        // console.log(this.perforModel.indVal);
                    }
                    if (this.stepParams[i].title == "专项奖励指标") {
                        this.perforModel.speVal = [];
                        for (
                            let m = 0;
                            m < this.perforModel.basData.length;
                            m++
                        ) {
                            if (
                                !this.perforModel.basData[m]
                                    .performanceFillInUserOtherList
                            ) {
                                this.perforModel.basData[
                                    m
                                ].performanceFillInUserOtherList =
                                    this.perforModel.basData[
                                        m
                                    ].performanceAllocationSchemeDetailList;
                            }
                            for (
                                let n = 0;
                                n <
                                this.perforModel.basData[m]
                                    .performanceFillInUserOtherList.length;
                                n++
                            ) {
                                if (
                                    this.perforModel.basData[m]
                                        .performanceFillInUserOtherList[n]
                                        .characteristic == "3"
                                ) {
                                    let val =
                                        this.perforModel.basData[m]
                                            .performanceFillInUserOtherList[n]
                                            .ratingDimension;
                                    let scoVal;
                                    // （0差  1一般  2优秀）
                                    if (val == "0") {
                                        scoVal =
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].poorScore;
                                    } else if (val == "1") {
                                        scoVal =
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].generalScore;
                                    } else if (val == "2") {
                                        scoVal =
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].goodScore;
                                    }
                                    repaSpearrArr.push({
                                        score: scoVal,
                                        ratingDimension:
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].ratingDimension,
                                        thirdLibraryId:
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].performanceIndicatorThirdLibrary
                                                .id,
                                    });
                                } else {
                                    spearrsep.push({
                                        score: this.perforModel.basData[m]
                                            .performanceFillInUserOtherList[n]
                                            .score,
                                        thirdLibraryId:
                                            this.perforModel.basData[m]
                                                .performanceFillInUserOtherList[
                                                n
                                            ].performanceIndicatorThirdLibrary
                                                .id,
                                    });
                                }
                            }
                        }
                        this.perforModel.speVal = [
                            ...spearrsep,
                            ...repaSpearrArr,
                        ];
                        // console.log(this.perforModel.speVal);
                    }
                }
            }
            // console.log(this.perforModel.speVal);
        },
        async addBatch(array, val) {
            let data = {
                userFillInId: this.fillperId, //列表的id
                allocationSchemeId: this.allocationSchemeId, //绩效方案id
                performanceFillInUserOtherList: this.perDetailList,
                postType: this.perpostType,
            };
            let { data: res } = await this.$axios.addBatch(data);
            this.saveextClick = true;
            this.saveextClickKing = false;
            this.nextClick = true;
            this.nextClickKing = false;
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                this.perfillList();
                if (val == "修改") {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    this.perfilldialogVisibleClose();
                } else if (val == "下一步") {
                    this.next();
                }
                // 回显的时候 修改 最后一步修改完成后关闭页面
                if (val == "修改" && this.active == "0") {
                    this.perfilldialogVisibleClose();
                }
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        postValueChange(value) {
            // console.log(value);
            let changeFlag = false;
            let nowItem = [];
            let firstItem = [];
            if (this.shareScopeEnd.length == 0) {
                this.poValue = value;
            } else {
                // 与原数组比对
                this.poValue.forEach((item) => {
                    if (item[0] !== this.shareScopeEnd[0][0]) {
                        // 一级标签不同
                        changeFlag = true;
                        firstItem.push(item);
                    }
                });
            }
            if (changeFlag && firstItem.length) {
                this.poValue = firstItem;
            } else if (!changeFlag && nowItem.length) {
                this.poValue = nowItem;
            }
            this.shareScopeEnd = this.poValue;
            // console.log(this.shareScopeEnd);
        },
        async findpost() {
            this.postOptions = [];
            let data = {
                dataType: "0", //科室绩效专用数据类型（ 0岗位职责 ）
            };
            let { data: res } = await this.$axios.optionselect(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                for (let i = 0; i < res.data.length; i++) {
                    this.postOptions.push({
                        label: res.data[i].dictName,
                        value: res.data[i].dictType,
                        dictType: res.data[i].dictType,
                        children: [],
                    });
                }
                this.postfindBySys();
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async postfindBySys() {
            for (let i = 0; i < this.postOptions.length; i++) {
                let data = {
                    type: this.postOptions[i].dictType,
                    deleteFlag: "0",
                    dictName: this.postOptions[i].label,
                };
                let res = await this.$axios.findBySys(data);
                // console.log(res);
                if (res.status == "200") {
                    for (let n = 0; n < res.data.length; n++) {
                        this.postOptions[i].children.push({
                            label: res.data[n].dictLabel,
                            value: res.data[n].dictLabel,
                        });
                    }
                }
            }
        },
        depfilldialogVisibleClose() {
            this.depfilldialogVisible = false;
            this.allocationSchemeId = "";
            this.departmentName = "";
            this.deppagNum = 1;
            this.fillList();
        },
        editKeshi(row) {
            // console.log(row);
            this.fillMonth = row.year + "年" + row.month;
            this.departmentName = row.departmentName;
            this.depfilldialogVisible = true;
            this.fillInId = row.id;
            this.belongDepartmentId = row.departmentId;
            this.allocationSchemeId = row.allocationSchemeId;
            this.dataType = row.dataType;
            this.checkFillInUsers();
            // 岗位
            this.findpost();
        },
        async perfillList() {
            let data = {
                pageNum: this.deppagNum, //页数
                pageSize: this.deppagSize, //每页显示条数
                deleteFlag: "0", //状态（0正常 1已删除）
                fillInId: this.fillInId, //科室绩效数据填报id
                departmentUserName: this.filName, //科室人员姓名
            };
            let { data: res } = await this.$axios.perfillList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.deptabData = res.rows;
                this.deptotal = res.total;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 科室人员姓名
        searchfilName() {
            this.deppagNum = 1;
            this.perfillList();
        },
        // 填报数据查看
        fillVieDat(row) {
            this.allocationSchemeId = row.allocationSchemeId;
            // this.perfilldialogVisible = true;
        },
        perfill(row) {
            // console.log(row);
            this.perpostType = row.postType;
            this.perfilldialogVisible = true;
            this.fillperId = row.id;
            this.userFillInId = row.fillInId;
            this.departmentUserName = row.departmentUserName;
            // console.log(row);
            if (row.otherLibraryStatus == 0) {
                this.findByType();
                this.nexTwoShow = false;
            } else {
                // 回显已填写的
                this.findFillInData();
                this.echoShow = true;
                this.nexShow = false;
            }
        },
        async findFillInData() {
            let data = {
                userFillInId: this.fillperId, //科室人员填报绩效数据id
                allocationSchemeId: this.allocationSchemeId,
                postType: this.perpostType,
            };
            let { data: res } = await this.$axios.findFillInData(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                let array = [],
                    checArr = [],
                    indArr = [],
                    indScoArr = [],
                    addArr = [],
                    perArr = [],
                    repPerArr = [];
                // console.log(this.perforModel.addValue);
                for (
                    let n = 0;
                    n < res.data.addResponsibilitiesLibraryList.length;
                    n++
                ) {
                    for (
                        let m = 0;
                        m <
                        res.data.addResponsibilitiesLibraryList[n]
                            .performanceFillInUserOtherList.length;
                        m++
                    ) {
                        // 回显选中的附加职责
                        if (
                            res.data.addResponsibilitiesLibraryList[n]
                                .performanceFillInUserOtherList[m].checkFlag ==
                            "1"
                        ) {
                            // console.log(
                            //     res.data.addResponsibilitiesLibraryList[n]
                            //         .performanceFillInUserOtherList[m]
                            // );
                            let obj = {};
                            obj.thirdLibraryId =
                                res.data.addResponsibilitiesLibraryList[
                                    n
                                ].performanceFillInUserOtherList[
                                    m
                                ].performanceIndicatorThirdLibrary.id;
                            obj.score =
                                res.data.addResponsibilitiesLibraryList[
                                    n
                                ].performanceFillInUserOtherList[m].score;
                            obj.checkFlag = "1";
                            addArr.push(obj);
                            // console.log(addArr);
                            this.perforModel.addValue = addArr;
                        }
                    }
                }

                this.perforModel.indVal = [];
                // 单项补助及奖惩指标
                for (
                    let n = 0;
                    n < res.data.individualSubsidyLibraryList.length;
                    n++
                ) {
                    for (
                        let m = 0;
                        m <
                        res.data.individualSubsidyLibraryList[n]
                            .performanceFillInUserOtherList.length;
                        m++
                    ) {
                        if (
                            res.data.individualSubsidyLibraryList[n]
                                .performanceFillInUserOtherList[m]
                                .performanceIndicatorThirdLibrary
                                .characteristic == "3"
                        ) {
                            let scObj = {};
                            scObj.thirdLibraryId =
                                res.data.individualSubsidyLibraryList[
                                    n
                                ].performanceFillInUserOtherList[
                                    m
                                ].performanceIndicatorThirdLibrary.id;
                            scObj.ratingDimension =
                                res.data.individualSubsidyLibraryList[
                                    n
                                ].performanceFillInUserOtherList[
                                    m
                                ].ratingDimension;

                            indScoArr.push(scObj);
                        } else {
                            let obj = {};
                            obj.thirdLibraryId =
                                res.data.individualSubsidyLibraryList[
                                    n
                                ].performanceFillInUserOtherList[
                                    m
                                ].performanceIndicatorThirdLibrary.id;
                            obj.score =
                                res.data.individualSubsidyLibraryList[
                                    n
                                ].performanceFillInUserOtherList[m].score;
                            indArr.push(obj);
                        }
                    }
                }
                this.perforModel.indVal.push(...indArr, ...indScoArr);
                // console.log(this.perforModel.indVal);
                // 个人考勤指标
                this.perforModel.perVal = [];
                for (
                    let n = 0;
                    n < res.data.personalAttendanceLibraryList.length;
                    n++
                ) {
                    for (
                        let m = 0;
                        m <
                        res.data.personalAttendanceLibraryList[n]
                            .performanceFillInUserOtherList.length;
                        m++
                    ) {
                        if (
                            res.data.personalAttendanceLibraryList[n]
                                .performanceFillInUserOtherList[m]
                                .performanceIndicatorThirdLibrary
                                .characteristic == "3"
                        ) {
                            let repObj = {};
                            repObj.thirdLibraryId =
                                res.data.personalAttendanceLibraryList[
                                    n
                                ].performanceFillInUserOtherList[
                                    m
                                ].performanceIndicatorThirdLibrary.id;
                            repObj.ratingDimension =
                                res.data.personalAttendanceLibraryList[
                                    n
                                ].performanceFillInUserOtherList[
                                    m
                                ].ratingDimension;

                            repPerArr.push(repObj);
                            // console.log(repPerArr)
                        } else {
                            let obj = {};
                            obj.thirdLibraryId =
                                res.data.personalAttendanceLibraryList[
                                    n
                                ].performanceFillInUserOtherList[
                                    m
                                ].performanceIndicatorThirdLibrary.id;
                            obj.score =
                                res.data.personalAttendanceLibraryList[
                                    n
                                ].performanceFillInUserOtherList[m].score;
                            perArr.push(obj);
                            // console.log(perArr)
                        }
                    }
                }
                this.perforModel.perVal.push(...perArr, ...repPerArr);
                // console.log(this.perforModel.perVal);

                // 专项奖励指标
                let sperArr = [],
                    perSpeArr = [];
                this.perforModel.speVal = [];
                for (
                    let n = 0;
                    n < res.data.specialRewardsLibraryList.length;
                    n++
                ) {
                    for (
                        let m = 0;
                        m <
                        res.data.specialRewardsLibraryList[n]
                            .performanceFillInUserOtherList.length;
                        m++
                    ) {
                        if (
                            res.data.specialRewardsLibraryList[n]
                                .performanceFillInUserOtherList[m]
                                .performanceIndicatorThirdLibrary
                                .characteristic == "3"
                        ) {
                            let repObj = {};
                            repObj.thirdLibraryId =
                                res.data.specialRewardsLibraryList[
                                    n
                                ].performanceFillInUserOtherList[
                                    m
                                ].performanceIndicatorThirdLibrary.id;
                            repObj.ratingDimension =
                                res.data.specialRewardsLibraryList[
                                    n
                                ].performanceFillInUserOtherList[
                                    m
                                ].ratingDimension;

                            perSpeArr.push(repObj);
                        } else {
                            let obj = {};
                            obj.thirdLibraryId =
                                res.data.specialRewardsLibraryList[
                                    n
                                ].performanceFillInUserOtherList[
                                    m
                                ].performanceIndicatorThirdLibrary.id;
                            obj.score =
                                res.data.specialRewardsLibraryList[
                                    n
                                ].performanceFillInUserOtherList[m].score;
                            sperArr.push(obj);
                        }
                    }
                }
                this.perforModel.speVal.push(...sperArr, ...perSpeArr);
                // console.log(this.perforModel.speVal);

                for (let i = 0; i < this.stepParams.length; i++) {
                    if (this.active == this.stepParams[i].index) {
                        if (this.stepParams[i].title == "个人考勤指标") {
                            for (
                                let n = 0;
                                n <
                                res.data.personalAttendanceLibraryList.length;
                                n++
                            ) {
                                res.data.personalAttendanceLibraryList[
                                    n
                                ].performanceAllocationSchemeDetailList =
                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceFillInUserOtherList;

                                for (
                                    let m = 0;
                                    m <
                                    res.data.personalAttendanceLibraryList[n]
                                        .performanceAllocationSchemeDetailList
                                        .length;
                                    m++
                                ) {
                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].ratingDimension =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[m]
                                            .ratingDimension + "";

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].score =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].score;

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].name =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.name;

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].explain =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.explain;

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].unit =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.unit;

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].intension =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.intension;

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].characteristic =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.characteristic;

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].suggestedScore =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.suggestedScore;
                                }
                            }
                            array = res.data.personalAttendanceLibraryList;
                        } else if (
                            this.stepParams[i].title == "单项补助及奖惩指标"
                        ) {
                            for (
                                let n = 0;
                                n <
                                res.data.individualSubsidyLibraryList.length;
                                n++
                            ) {
                                res.data.individualSubsidyLibraryList[
                                    n
                                ].performanceAllocationSchemeDetailList =
                                    res.data.individualSubsidyLibraryList[
                                        n
                                    ].performanceFillInUserOtherList;

                                if (
                                    res.data.individualSubsidyLibraryList[n]
                                        .name == "工作评分"
                                ) {
                                    for (
                                        let m = 0;
                                        m <
                                        res.data.individualSubsidyLibraryList[n]
                                            .performanceFillInUserOtherList
                                            .length;
                                        m++
                                    ) {
                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].ratingDimension =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].ratingDimension.toString();
                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].name =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.name;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].explain =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.explain;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].unit =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.unit;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].intension =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.intension;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].characteristic =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.characteristic;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].suggestedScore =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.suggestedScore;
                                    }
                                } else {
                                    for (
                                        let m = 0;
                                        m <
                                        res.data.individualSubsidyLibraryList[n]
                                            .performanceFillInUserOtherList
                                            .length;
                                        m++
                                    ) {
                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].ratingDimension =
                                            res.data
                                                .individualSubsidyLibraryList[n]
                                                .performanceFillInUserOtherList[
                                                m
                                            ].ratingDimension + "";

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].score =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].score;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].name =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.name;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].explain =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.explain;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].unit =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.unit;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].intension =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.intension;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].characteristic =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.characteristic;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].suggestedScore =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[
                                                m
                                            ].performanceIndicatorThirdLibrary.suggestedScore;
                                    }
                                }
                            }
                            array = res.data.individualSubsidyLibraryList;
                        } else if (this.stepParams[i].title == "附加职责") {
                            for (
                                let n = 0;
                                n <
                                res.data.addResponsibilitiesLibraryList.length;
                                n++
                            ) {
                                res.data.addResponsibilitiesLibraryList[
                                    n
                                ].performanceAllocationSchemeDetailList =
                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceFillInUserOtherList;
                                for (
                                    let m = 0;
                                    m <
                                    res.data.addResponsibilitiesLibraryList[n]
                                        .performanceFillInUserOtherList.length;
                                    m++
                                ) {
                                    // 回显选中的附加职责
                                    if (
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[m]
                                            .checkFlag == "1"
                                    ) {
                                        checArr.push(
                                            res.data
                                                .addResponsibilitiesLibraryList[
                                                n
                                            ].performanceFillInUserOtherList[m]
                                                .id
                                        );
                                    }

                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceFillInUserOtherList[m].name =
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].performanceIndicatorThirdLibrary.name;

                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceFillInUserOtherList[
                                        m
                                    ].explain =
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].performanceIndicatorThirdLibrary.explain;

                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceFillInUserOtherList[m].unit =
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].performanceIndicatorThirdLibrary.unit;

                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceFillInUserOtherList[
                                        m
                                    ].intension =
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].performanceIndicatorThirdLibrary.intension;

                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceFillInUserOtherList[
                                        m
                                    ].characteristic =
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].performanceIndicatorThirdLibrary.characteristic;
                                }
                            }
                            array = res.data.addResponsibilitiesLibraryList;
                            // console.log(array);
                            this.checkKeys = checArr;
                        } else if (this.stepParams[i].title == "专项奖励指标") {
                            for (
                                let n = 0;
                                n < res.data.specialRewardsLibraryList.length;
                                n++
                            ) {
                                res.data.specialRewardsLibraryList[
                                    n
                                ].performanceAllocationSchemeDetailList =
                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceFillInUserOtherList;
                                for (
                                    let m = 0;
                                    m <
                                    res.data.specialRewardsLibraryList[n]
                                        .performanceFillInUserOtherList.length;
                                    m++
                                ) {
                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].score =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].score;

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].ratingDimension =
                                        res.data.specialRewardsLibraryList[n]
                                            .performanceFillInUserOtherList[m]
                                            .ratingDimension + "";

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceFillInUserOtherList[m].name =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].performanceIndicatorThirdLibrary.name;

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceFillInUserOtherList[
                                        m
                                    ].explain =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].performanceIndicatorThirdLibrary.explain;

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceFillInUserOtherList[m].unit =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].performanceIndicatorThirdLibrary.unit;

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceFillInUserOtherList[
                                        m
                                    ].intension =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].performanceIndicatorThirdLibrary.intension;

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceFillInUserOtherList[
                                        m
                                    ].characteristic =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].performanceIndicatorThirdLibrary.characteristic;

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceFillInUserOtherList[
                                        m
                                    ].suggestedScore =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceFillInUserOtherList[
                                            m
                                        ].performanceIndicatorThirdLibrary.suggestedScore;
                                }
                            }
                            array = res.data.specialRewardsLibraryList;
                        }
                    }
                }

                this.perforModel.basData = array;
                // console.log(array);
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        // 修改审核状态
        async editSta(val) {
            let data = _qs.stringify({
                id: val.id, //科室人员填报绩效数据id
                auditStatus: val.auditStatus, //审核状态（0正常  1审核未通过）
            });
            let { data: res } = await this.$axios.perfillUpdaStatus(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.perfillList();
                this.$message({
                    message: res.msg,
                    type: "success",
                });
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        async findByType() {
            let data = _qs.stringify({
                id: this.allocationSchemeId, //科室人员填报绩效数据id
                postType: this.perpostType,
            });
            let { data: res } = await this.$axios.findByOtherType(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                // console.log(res.data);
                let array = [];

                for (let i = 0; i < this.stepParams.length; i++) {
                    if (this.active == this.stepParams[i].index) {
                        if (this.stepParams[i].title == "个人考勤指标") {
                            for (
                                let n = 0;
                                n <
                                res.data.personalAttendanceLibraryList.length;
                                n++
                            ) {
                                for (
                                    let m = 0;
                                    m <
                                    res.data.personalAttendanceLibraryList[n]
                                        .performanceAllocationSchemeDetailList
                                        .length;
                                    m++
                                ) {
                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].score = "0";

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].ratingDimension = "2";

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].name =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.name;

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].explain =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.explain;

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].unit =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.unit;

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].intension =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.intension;

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].characteristic =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.characteristic;

                                    res.data.personalAttendanceLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].suggestedScore =
                                        res.data.personalAttendanceLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.suggestedScore;
                                }
                            }
                            array = res.data.personalAttendanceLibraryList;
                        } else if (
                            this.stepParams[i].title == "单项补助及奖惩指标"
                        ) {
                            for (
                                let n = 0;
                                n <
                                res.data.individualSubsidyLibraryList.length;
                                n++
                            ) {
                                if (
                                    res.data.individualSubsidyLibraryList[n]
                                        .name == "工作评分"
                                ) {
                                    for (
                                        let m = 0;
                                        m <
                                        res.data.individualSubsidyLibraryList[n]
                                            .performanceAllocationSchemeDetailList
                                            .length;
                                        m++
                                    ) {
                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].name =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.name;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].explain =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.explain;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].unit =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.unit;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].intension =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.intension;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].characteristic =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.characteristic;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].suggestedScore =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.suggestedScore;
                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].ratingDimension = "2";
                                    }
                                } else {
                                    for (
                                        let m = 0;
                                        m <
                                        res.data.individualSubsidyLibraryList[n]
                                            .performanceAllocationSchemeDetailList
                                            .length;
                                        m++
                                    ) {
                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].score = "0";

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].ratingDimension = "2";

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].name =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.name;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].explain =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.explain;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].unit =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.unit;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].intension =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.intension;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].characteristic =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.characteristic;

                                        res.data.individualSubsidyLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].suggestedScore =
                                            res.data.individualSubsidyLibraryList[
                                                n
                                            ].performanceAllocationSchemeDetailList[
                                                m
                                            ].performanceIndicatorThirdLibrary.suggestedScore;
                                    }
                                }
                            }
                            array = res.data.individualSubsidyLibraryList;
                        } else if (this.stepParams[i].title == "附加职责") {
                            for (
                                let n = 0;
                                n <
                                res.data.addResponsibilitiesLibraryList.length;
                                n++
                            ) {
                                for (
                                    let m = 0;
                                    m <
                                    res.data.addResponsibilitiesLibraryList[n]
                                        .performanceAllocationSchemeDetailList
                                        .length;
                                    m++
                                ) {
                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].name =
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.name;

                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].explain =
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.explain;

                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].unit =
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.unit;

                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].intension =
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.intension;

                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].characteristic =
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.characteristic;

                                    res.data.addResponsibilitiesLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].suggestedScore =
                                        res.data.addResponsibilitiesLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.suggestedScore;
                                }
                            }
                            array = res.data.addResponsibilitiesLibraryList;
                        } else if (this.stepParams[i].title == "专项奖励指标") {
                            for (
                                let n = 0;
                                n < res.data.specialRewardsLibraryList.length;
                                n++
                            ) {
                                for (
                                    let m = 0;
                                    m <
                                    res.data.specialRewardsLibraryList[n]
                                        .performanceAllocationSchemeDetailList
                                        .length;
                                    m++
                                ) {
                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].score = "0";

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].ratingDimension = "2";

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].name =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.name;

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].explain =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.explain;

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].unit =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.unit;

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].intension =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.intension;

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].characteristic =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.characteristic;

                                    res.data.specialRewardsLibraryList[
                                        n
                                    ].performanceAllocationSchemeDetailList[
                                        m
                                    ].suggestedScore =
                                        res.data.specialRewardsLibraryList[
                                            n
                                        ].performanceAllocationSchemeDetailList[
                                            m
                                        ].performanceIndicatorThirdLibrary.suggestedScore;
                                }
                            }
                            array = res.data.specialRewardsLibraryList;
                        }
                    }
                }
                this.perforModel.basData = array;
                // console.log(array);
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        perfilldialogVisibleClose() {
            this.perfilldialogVisible = false;
            this.perDetailList = [];
            this.fillperId = "";
            this.echoShow = false;
            this.saveShow = false;
            this.perforModel = {
                basData: [],
                addValue: [],
                speVal: [], // 专项奖励
                indVal: [], // 单项补助及奖惩指标
                addVal: [], // 附加职责
                perVal: [], // 个人考勤指标
            };
            this.departmentUserName = "";
            this.userFillInId = "";
            this.nexShow = true;
            this.nexTwoShow = true;
            this.perShow = false;
            this.active = 0;
        },
        addKeshi() {
            if (this.diaBtn == "新建") {
                // 新建
                this.addworkIn();
            } else if (this.diaBtn == "修改") {
                // 修改
                this.updateworkIn();
            }
        },
        addworkIn() {
            this.$refs.formRef.validate(async (valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                    let data = _qs.stringify({
                        allocationSchemeId: this.formModel.allplan, //科室绩效方案id
                        departmentId: this.formModel.departmentId, //科室id
                        year: this.formModel.date.split("-")[0], //年份
                        month: this.formModel.date.split("-")[1], //月份
                        remark: this.formModel.remark, //备注
                        dataType: this.formModel.position,
                        name: this.formModel.name,
                    });

                    let { data: res } = await this.$axios.fillAdd(data);
                    // console.log(res);
                    this.addDepClick = true;
                    this.addDepClickKing = false;
                    if (res.code == 401) {
                        this.$router.push("/login");
                    } else if (res.code == 200) {
                        this.fillList();
                        this.AdddialogVisibleClose();
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                }
            });
        },
        updateworkIn() {
            this.$refs.formRef.validate(async (valid) => {
                if (valid) {
                    this.addDepClick = false;
                    this.addDepClickKing = true;
                }
            });
        },
        addmonfil() {
            this.AdddialogVisible = true;
        },
        async perallSchFind() {
            let data = {
                deleteFlag: "0", //状态（0正常 1已删除）
                departmentId: this.formModel.departmentId, //科室id
            };
            let res = await this.$axios.perallSchFind(data);
            // console.log(res);
            if (res.status == 200) {
                this.planoptions = res.data;
            }
        },
        AdddialogVisibleClose() {
            this.AdddialogVisible = false;
            this.diaBtn = "新建";
            this.monthtit = "新建月度填报";
            this.$refs.formRef.resetFields();
            this.formModel = {
                departmentId: "",
                date: "",
                remark: "",
                allplan: "",
                position: "",
            };
            this.planoptions = [];
        },
        async findDepartment() {
            let data = {
                hospitalId: window.sessionStorage.getItem("hospitalId"), //登录后返回的 医院id
                nameLike: "", //查询用的 科室名称
                orderByColumn: "createDate", //排序参数 updateDate 更新时间
                isAsc: "desc", //asc 升序 desc降序
            };
            let { data: res } = await this.$axios.findDepartment(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.departoptions = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    this.depOptions.push({
                        label: res.data[i].name,
                        value: res.data[i].id,
                        children: [],
                    });
                }
                this.findByhosInward();
            }
        },
        async findByhosInward() {
            for (let i = 0; i < this.depOptions.length; i++) {
                let finddata = {
                    deleteFlag: "0",
                    id: "",
                    departmentId: this.depOptions[i].value,
                };
                let findres = await this.$axios.findByhosInward(finddata);
                // console.log(findres);
                if (findres.code == 401) {
                    this.$router.push("/login");
                } else if (findres.status == 200) {
                    // console.log(findres.data);
                    for (let n = 0; n < findres.data.length; n++) {
                        if (
                            findres.data[n].departmentId ==
                            this.depOptions[i].value
                        ) {
                            this.depOptions[i].children.push({
                                label: findres.data[n].name,
                                value: findres.data[n].id,
                            });
                        }
                    }
                }
            }
        },
        // 列表数据
        async fillList() {
            let data = {
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                deleteFlag: "0", //状态（0正常 1已删除）
                departmentId: this.searDepVal, //科室id
                year: this.searYear, //年份
                month: this.searMonth, //月份
            };
            let { data: res } = await this.$axios.fillList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            } else {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.fillList();
        },
        handledepCurrentChange(val) {
            this.deppagNum = val;
            this.perfillList();
        },
        handleworkCurrentChange(val) {
            this.workpagNum = val;
        },
    },
};
</script>

<style lang="less" scoped>
@import "../../../assets/css/Indicator/depperbusdafi.less";
</style>
